import { CircularProgress } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import TestSeriesCard from '../Card/TestSeriesCard';
import styles from '../styles/TestSeries.module.css';
import useGetAssignedCoursesById from '../testSeriesHooks/useGetAssignedCoursesById';
import GridContainer from './Grid/GridContainer';
import GridItem from './Grid/GridItem';

export default function TestSeries(props) {
  const { t } = useTranslation();

  const { isLoading, error, data: courses, refetch } = useGetAssignedCoursesById();

  const [course, setCourses] = React.useState([]);
  console.log(courses, "courselist")
  console.log(typeof (courses), "courselist")

  return (
    <div>

      <h4 className={styles.cardPageTitle}>{t('assignedTestSeries')}</h4>
      {
        isLoading ?
          <CircularProgress style={{ color: '#FF802B' }} /> :
          <GridContainer>
            {
              courses && courses.length > 0 ? Object.values(courses).map((i, x) => (
                <GridItem xs={12} sm={6} md={6} lg={4}>
                  <TestSeriesCard
                    courseName={courses[x].courseName}
                    objective={courses[x].objective}
                    targetAudience={courses[x].targetAudience}
                    image={courses[x].image}
                    description={courses[x].description}
                    courseId={courses[x].courseId}
                    code={courses[x].code}
                    courseTestStatusObj={courses[x].courseTestStatusObj}
                  />
                </GridItem>
              )) :
                <GridItem xs={12} sm={6} md={6} lg={4}>
                  <TestSeriesCard
                    courseName={t('testseriesnotfound')}
                    // objective={courses[x].objective}
                    image={'record-not-found.png'}
                  // description={courses[x].description}
                  // courseId={courses[x].courseId}
                  // code={courses[x].code}
                  // courseTestStatusObj={courses[x].courseTestStatusObj}
                  />
                </GridItem>
            }
          </GridContainer>
      }

    </div>
  );
}