import axios from "axios";
import { useQuery } from "react-query";
import { configUrl } from "../../config";

export default function useGetAllNotifications(userId) {
  return useQuery([`testsByUser${userId}`, userId], async () => {
    console.log('in here')
    if (!userId) {
      return;
    }
    let result = await axios.get(
      `${configUrl.AssessmentServer}/api/Counselling/getAllNotifications/${userId}`
    );

    return result.data;
  },  
  {
    staleTime: 1000 * 60 * 5,
  });
}
