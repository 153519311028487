import React from "react";
//import QuestionComponent from "./QuestionComponent";

// import GridContainer from "components/Grid/GridContainer";
// import GridItem from "components/Grid/GridItem";

// import Button from "components/CustomButtons/Button.js";
// import Question from "components/Questions/Question";

import { CircularProgress } from '@mui/material';
import Card from "../../Card/Card.js";
import CardBody from "../../Card/CardBody";
import DataTable from "../DataTable.js";
// import CardText from "components/Card/CardText.js";
// import Box from "@material-ui/core/Box";
// import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

// @material-ui/icons

// core components
// import InfoArea from "components/InfoArea/InfoArea.js";
// import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
// import { TestContext } from "contexts/TestContext";
// import WelcomeScreen from "components/Questions/WelcomeScreen";
// import ConcludeScreen from "components/Questions/ConcludeScreen";
// import FeedbackFailScreen from "components/Questions/FeedbackFailScreen";
// import FeedbackPassScreen from "components/Questions/FeedbackPassScreen";
// import FeedbackMasteryScreen from "components/Questions/FeedbackMasteryScreen";
import { useTranslation } from "react-i18next";
import attemptwiseService from "../../services/attemptwiseService.js";
import styles from '../../styles/QuestionWrapper.module.css';
import CustomSelect from "../CustomSelect/CustomSelect.js";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Question from "./Question";
const EvaluationQuestionWrapper = ({ userId, testId, isCourse }) => {
  const { t } = useTranslation();
  console.log("useId,testId,isCourse", userId, testId, isCourse)
  // const { data } = useGetTestResults(userId, testId, isCourse);

  const attemptwiseResult = async (userId, testId, attemptId) => {
    setLoading(true);
    let testResult = await attemptwiseService.attemptwiseService(userId, testId, attemptId);
    console.log(testResult, 'new Result method')
    setData(testResult)
    setLoading(false);
  }
  const [attemptId, setAttemptId] = React.useState(1);
  React.useEffect(() => {
    attemptwiseResult(userId, testId, attemptId);
  }, [attemptId])

  const [questions, setQuestions] = React.useState(null);
  const [attempts, setAttempts] = React.useState(null);
  //  ( [
  //   { id: 1, name: "1" },
  //   { id: 2, name: "2" },
  //   { id: 3, name: "3" },
  //   { id: 4, name: "4" },
  //   { id: 5, name: "5" }
  // ]);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  console.log(data, 'result data1')
  const [actualScore, setActualScore] = React.useState(null);
  const [totalScore, setTotalScore] = React.useState(null);
  const [showResult, setShowResult] = React.useState();   //for show result or not please check below commented JSX code.
  React.useEffect(() => {
    console.log("result data", data)
    if (data && data?.testDetails) {
      let attempOptions = [];

      for (let i = 1; i <= data?.totalAttempts; i++) {
        attempOptions.push(i);
      }

      setAttempts([...attempOptions]);

      // for (let i = 0; i < data?.testDetails["$values"].length; i++) {
      //   console.log("Q", JSON.parse(data?.testDetails["$values"][i].attemptAnswers).flat());
      //   // console.log("QPrev", data);

      // }

      getQuestions(1)
    }
  }, [data]);
  const getQuestions = (attempt) => {
    console.log(data, 'indatas')
    let qs = data?.testDetails          //["$values"].filter((x) => x.attemptNumber == attempt);
    console.log("result qs", qs?.actualScore)
    setActualScore(qs?.actualScore);
    setTotalScore(qs?.totalScore);
    console.log("qs", qs);
    console.log(JSON.parse(qs.attemptDetails)?.value?.showResult, 'attemptDetailsqs')
    setShowResult(JSON.parse(qs.attemptDetails)?.value?.showResult)
    setQuestions(JSON.parse(qs.attemptAnswers).flat());
    console.log(JSON.parse(qs.attemptDetails), 'loggedParsedAnswer');
    console.log(JSON.parse(qs.attemptAnswers).flat(), 'loggedParsedAnswer');

  };

  return (
    <div className={styles.evaluationQuestionWrapperCard}>
      {
        console.log(data.length == 0, 'datakkk')
      }
      {
        data.length == 0 ?
          <div style={{ textAlign: 'center' }}>
            <CircularProgress style={{ color: '#FF802B' }} /><br />
            <h5>Loading...</h5>
          </div>
          :
          <GridContainer justifyContent="center">
            <GridItem xs={11} sm={11} md={3} >
              {attempts && attempts.length > 0 && (
                <CustomSelect
                  options={attempts.map((x) => ({ id: x, name: x }))}
                  value={1}
                  setvalue={(v) => {
                    console.log(v, 'vvvkkk');
                    setAttemptId(v);
                    getQuestions(v);
                  }}
                  label={t('selectAttempt')}
                />
              )}
            </GridItem>
            <GridItem xs={11} sm={11} md={1} ></GridItem>
            <GridItem xs={11} sm={11} md={5} style={{ textAlign: 'end', marginTop: '10px', color: '#EC9706' }}>
              {
                // will check with preview button.
                // showResult == 1 &&
                (<p style={{ fontSize: '16px' }}>{t("Result")} {actualScore}/{totalScore}</p>)
              }
            </GridItem>
            <GridItem xs={11} sm={11} md={9} style={{ marginTop: '5px' }}>
              <DataTable data={data?.poolAnalysisDetails?.$values} />
            </GridItem>


            <GridItem xs={12} sm={12} md={10}>
              {questions && (
                <Card>
                  {/* <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <HelpOutlineIcon />
                </CardIcon>
              </CardHeader> */}
                  <CardBody className={styles.cardbody}>
                    {loading == true && (
                      <div style={{ textAlign: 'center' }}>
                        <CircularProgress style={{ color: '#FF802B' }} /><br />
                        <h5>Loading...</h5>
                      </div>)
                    }
                    {questions &&
                      questions.length > 0 &&
                      questions.map((x, i) => (
                        <Question
                          qNoes={i + 1}
                          key={i}
                          question={{ ...x, isSubmitted: true }}
                          questionObj={JSON.parse(data?.questionObj)}
                          currentQuestionIndex={i}
                          updateQuestion={() => { }}
                          preview={true}
                        />
                        // <h1>Need to add section line here, </h1>

                      ))}
                  </CardBody>
                </Card>
              )}
            </GridItem>
          </GridContainer>
      }
    </div>
  );
};

export default EvaluationQuestionWrapper;
