import { InfoCircleOutlined } from '@ant-design/icons';
import { ArrowBack } from "@material-ui/icons";
import { Alert, Snackbar } from "@mui/material";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Button, DatePicker, Form, Input, Modal, Select, Tooltip, message } from "antd";
import axios from "axios";
import i18next from "i18next";
import moment from 'moment';
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LoginContext } from "../contexts/LoginContext";
import getProviderByKey from "../services/ProviderService";
import {
  getCity,
  getClasses,
  getEducationBoard,
  getInterest,
  getLanguage,
  getState,
} from "../services/dropdownService";
import { onNextService, onSignUpService } from "../services/signupService";
import styles from "../styles/signin.module.css";
import getStudentDetailByEmail from '../userHooks/getStudentDetailByEmail';
import Google from "./Common/GoogleAuthLogin";

const SignInup = (prop) => {
  const [form] = Form.useForm();
  useEffect(() => {
    let currentLang = sessionStorage.getItem("lang");
    i18next.changeLanguage(currentLang);
  }, [prop]);
  const formItemLayout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 20,
    },
  };
  const formItemLayout1 = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 15,
    },
  };
  const formItemLayout2 = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 10,
    },
  };
  const { t } = useTranslation();
  const dateFormat = "DD/MM/YYYY";
  const navigate = useNavigate();
  const {
    setProviderId,
    providerId,
    user,
    UserId,
    setUserId,
    setProviderKey,
    setProviderName,
    setEmailC,
    setUserName,
  } = useContext(LoginContext);
  const [StatesData, setStatesData] = useState([]);
  const [CityData, setCityData] = useState([]);
  const [LanguageData, setLanguageData] = useState([]);
  const [EduboardData, setEduboardData] = useState([]);
  const [ClassesdData, setClassesdData] = useState([]);
  const [InterestData, setInterestData] = useState([]);
  const [RespId, setRespId] = useState();
  const [step, setStep] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [confirmVisible, setConfirmVisible] = React.useState(false);
  const [gender, SetGender] = React.useState(0);
  const [providerIdByUrl, setProviderIdByUrl] = React.useState(null);
  const [disableFlag, setDisableFlag] = React.useState(false);
  const [globalData, setGlobalData] = React.useState(null);
  const { id } = useParams();


  console.log('setting the linkProviderId', id);

  console.log("This is implementatiom--" + id);

  console.log("Calling Provider Service");
  //ProviderService.getProviderByKey(pKey);
  // if(providerIdByUrl ==null){
  //  setProviderIdByUrl= getProviderByKey(id);
  // }

  const isValidId = async () => {
    try {

      const providerData = await getProviderByKey(id);
      console.log("providerdaada", providerData)
      setProviderIdByUrl(providerData.providerId);
      setProviderId(providerData.providerId);
      setProviderKey(providerData.providerKey);
      setProviderName(providerData.name);
      console.log("names:", providerData.name)
      console.log(providerIdByUrl, "inside IsvalidId ");
      // If the providerData is retrieved, the ID is valid
      if (providerData == null) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      //  (ID not found ) return false
      console.log(error);
    }
  };

  useEffect(() => {
    if (providerIdByUrl === null) {
      isValidId()
        .then(isValid => {
          if (!isValid) {
            message.error("Path not valid.");
            console.log("im false");
            setTimeout(() => {
              navigate('/');
            }, 2000);
          } else {
            console.log("im true");
          }
        })
        .catch(error => {
          console.error('Error fetching provider data:', error);
        });
    }
  }, []);


  console.log("providerbyurl is", providerIdByUrl);

  //console.log('prams: ', params)
  // if (params && params.id) {
  //   if (linkProviderId !== params.id)
  //     console.log('setting the linkProviderId', params.id)
  //   setLinkProviderId(params.id)
  // }

  const onChange = (nextStep) => {
    setStep(nextStep < 0 ? 0 : nextStep > 2 ? 2 : nextStep);
  };
  const onPrev = () => {
    onChange(step - 1);
  };

  const handleModal = () => {
    setConfirmVisible(true);
  };

  const handleDisabledDate = current => {
    // Disable future dates
    const today = new Date();
    return current && current > today;
  };
  const onGoogleAuthClick = (email, auth, newUser) => {
    console.log(email, 'email', auth, 'auth', newUser, 'newUser')
    if (auth) {
      // TODO
      message.success(`Hi ${newUser.firstName} ${newUser.lastName}, Your registration is completed check the email for login details,We will notify you very soon once your request is approved.`)
      setTimeout(() => {
        navigate(`/signIn/${id}`)
      }, 5000);
      // alert("Your google registration is completed : " + email);
    } else {
      message.success("Your google registration is failed for " + email)
      // alert("Your google registration is failed : " + email);
    }
  };

  const getServices = async () => {
    let isActive = true;
    const states = await getState("State", isActive);
    const city = await getCity("City", isActive);
    const language = await getLanguage("Language", isActive);
    const eduboard = await getEducationBoard("EduBoard", isActive);
    const classes = await getClasses("Class", isActive);
    const Interest = await getInterest(providerId);

    console.log("recvd state data", states.$values);
    console.log("recvd state data sec", eduboard, classes, language);
    console.log("Recieved data for interest", Interest);
    if (!states && !city && !language && !eduboard && !classes && !Interest) {
      setStatesData([]);
      setCityData([]);
      setLanguageData([]);
      setEduboardData([]);
      setClassesdData([]);
      setInterestData([]);
    } else {
      setStatesData(states.$values);
      setCityData(city.$values);
      setLanguageData(language.$values);
      setEduboardData(eduboard.$values);
      setClassesdData(classes.$values);
      setInterestData(Interest.$values);
    }

    //---
    // if(!Interest){
    //   setInterestData([]);

    // }else{
    //   setInterestData(Interest.$values);
    // }
    //---
  };



  useEffect(() => {
    setProviderId(providerIdByUrl);
    // BSS121
    axios.defaults.headers.common = {
      providerId: providerIdByUrl,
    };
  }, [prop]);

  useEffect(() => {
    getServices();
  }, [prop]);

  useEffect(() => {
    if (globalData) {
      const tempState = StatesData ? StatesData[globalData?.studetnInfo?.state]?.valueString ? StatesData[globalData?.studetnInfo?.state].valueString : null : null;
      form.setFieldsValue({
        fullName: globalData?.studetnInfo?.fullName || "",
        mobile: globalData?.studetnInfo?.mobile || "",
        //gender: 1,
        gender: globalData?.studetnInfo?.gender || null,
        dob: globalData?.studetnInfo?.dob ? moment(globalData.studetnInfo.dob) : null,
        passCode: globalData?.studetnInfo?.passCode || "",
        confirm: globalData?.studetnInfo?.passCode || "",
        address: globalData?.studetnInfo?.address || "",
        //state: globalData?.studetnInfo?.state || null,
        // state: tempState || "",
        // city: globalData?.studetnInfo?.city || null,
        // pinCode: globalData?.studetnInfo?.pinCode || null,
        // Language: globalData?.educationInfo?.language || null,
        // Interest: globalData?.intrestList?.$values[0]?.Interest || null,
        // Board: globalData?.educationInfo?.board || null,
        // Class: globalData?.educationInfo?.class || null,
      });
      console.log(StatesData, globalData, "ABCL");
      console.log(tempState, "AWQ")
    } else {
      form.setFieldsValue({
        fullName: "",
        mobile: "",
        gender: "",
        dob: null,
        passCode: "",
        confirm: "",
        address: "",
        state: null,
        city: null,
        pinCode: null,
        Language: null,
        Interest: null,
        Board: null,
        Class: null,
      });
    }
  }, [globalData]);

  const onSignUp = async (values) => {
    message.success("Thanks for signing up. We will notify you very soon once your request is approved.");
    console.log(values);
    let pyload = Object.assign(defalutDta, values);
    console.log(pyload, "signup Pyload");
    const signUpservice = await onSignUpService(pyload);
    if (signUpservice) {
      setProviderId(signUpservice.providerId);
      setUserId(signUpservice.userId);
      setUserName(signUpservice.fullName);
      setEmailC(signUpservice.email);
    }
    sessionStorage.setItem("EducationInfo", JSON.stringify(signUpservice));
    // localStorage.setItem("ParentInfo",JSON.stringify([]));

    navigate(`/${id}`);
  };
  const onNext = async (event) => {

    console.log(event.passCode, "passcode valid");
    console.log(event, "onNext funtion call");
    let pylod = Object.assign(defalutDta, event);
    const nextService = await onNextService(pylod);
    if (nextService?.response?.status === 401 && nextService?.response?.data === "User already exist") {
      message.error("User already exist");
      return;
    }
    onChange(step + 1);
    sessionStorage.setItem("PersonalInfo", JSON.stringify(nextService));
    setRespId(nextService.userId);
    console.log("resp", nextService);
    console.log("userId", nextService.userId);
  };
  let defalutDta = {
    createdBy: user ?? "system",
    updatedBy: user ?? "system",
    userTypeId: 4,
    providerId: providerIdByUrl,
    isActive: false,
    educationId: 0,
    userId: RespId,
    createdDate: new Date(),
    updatedDate: new Date(),
    userName: email,
  };


  const handleGenderChange = (value) => {
    console.log("Gender value is: ", value);
    SetGender(value);
    console.log("Gender value is: ", gender);
  }

  const handleOnChange = (e) => {
    // e.preventDefault();
    // // setPasswordInput(evnt.target.value);
    // const { name, value } = e.target;
    // setStudentInfo({ ...studentinfo, [name]: value })
    console.log(e.target, "handle change");
  };
  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleEmailChange = async (event) => {
    const email = event.target.value;
    setEmail(email);
    console.log(email, "Email");
    const data = await getStudentDetailByEmail.getStudentDetailByEmail(email);
    if (data?.messege === 'User already exists') {
      message.error("User already exist");
    }
    console.log(data, "EmailData");
    //globalData = data;
    setGlobalData(data);
    if (data && data.status !== 400) {
      console.log(data, "EmailData");
      setDisableFlag(true);
      handleModal();
    } else {
      setDisableFlag(false);
    }
  };

  const strongValidator = (rule, value, callback) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=])/;
    if (!value.match(regex)) {
      return callback(true);
    }
    callback();
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div
      className="container mt-5"
      style={{ backgroundColor: "#EFD7AF80 0% 0%" }}
    >
      {/* <Card style={{ backgroundColor: "transparent" }}> */}
      <div className="row">
        <div
          className="respImage col-sm-2"
          style={{
            backgroundImage: `url('../signup1.jpg')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
          }}
        >
          {/* <div className="example col-sm-2" style={{ background: `linear-gradient(to bottom, #EC9706  0%, #FFFFFF  100%)`,
              opacity:'0.62'}}> */}
          {step === 0 ? (
            <h4
              style={{
                color: "#000000",
                textAlign: "left",
                marginBottom: "25px",
                fontWeight: "bold",
              }}
              onClick={() => navigate(`/signIn/${id}`)}
            >
              <Link to={() => navigate(`/signIn/${id}`)}>
                <ArrowBack
                  style={{
                    fontWeight: "bold",
                    fontSize: "22px",
                    color: "000000",
                  }}
                />
              </Link>{" "}
              {t("@signINRedirect")}
            </h4>
          ) : (
            <h4
              style={{
                color: "#000000",
                textAlign: "left",
                marginBottom: "25px",
                fontWeight: "bold",
              }}
            >
              <ArrowBack
                style={{ fontWeight: "bold", fontSize: "22px" }}
                onClick={onPrev}
              />{" "}
              {t("almostdone")}
            </h4>
          )}
          {/* <img
                    className="example"
                    src="signUpleft.png"
                    style={{ top: '187',
                      left: '124',
                      borderRadius: "50%",
                      height: '137px',
                      width: '150px',
                      marginTop: '50%',
                     }}
                    
                  /> */}
        </div>
        <div className="col-sm-10" style={{ backgroundColor: "white" }}>
          <div style={{ textAlign: "end", color: "#FF802B" }}>
            <h4>{`${t("step")}  ${step + 1} of 2`}</h4>
          </div>

          {console.log(globalData, "globalData")}
          {step === 0 ? (
            <Form form={form} name="normal_signup" className="signUpForm" onFinish={onNext}>
              <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={open}
                onClose={handleClose}
                autoHideDuration={1000}
              >
                <Alert
                  severity="success"
                  onClose={handleClose}
                  sx={{ fontSize: "15px", width: "100%" }}
                >
                  {t("userUpdated")}
                </Alert>
              </Snackbar>


              <div className="row">
                {/* <div className="col-sm">
                    <label className={styles.label} for="username">UserName</label>
                    <Form.Item
                        id="username"
                        {...formItemLayout}
                        name="UserName"
                        rules={[
                          {
                            required: true,
                            message:t("reqUsername")

                          },
                          // {
                          //   type: "email",
                          //   message: t("reqEmailValid"),
                          // }
                        ]}
                        
                      >
                        <Input onChange={handleOnChange} placeholder="Username" />
                      </Form.Item>
                  </div> */}



                <div className="col-sm-5" style={{ textAlign: "left", alignItems: 'left' }}>

                  <div style={{ display: 'flex' }}>
                    <label className={styles.label} style={{ marginRight: '8px' }} for="email">
                      Email  <span style={{ color: "red" }}><b>*</b></span>
                    </label>
                    <Tooltip placement="top" title="Please enter a valid email">
                      <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                    </Tooltip>
                  </div>

                  <Form.Item
                    id="email"
                    // {...formItemLayout1}
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: 'Please enter a valid email',
                      },
                      {
                        required: true,
                        message: 'Please enter a valid email',
                      },

                    ]}
                  >
                    <Input
                      onChange={handleEmailChange} placeholder="Email" />
                  </Form.Item>

                </div>
                <div className="example col-sm-2"></div>

                <div className="col-sm-5" style={{ textAlign: "left", alignItems: 'left' }}>

                  <div style={{ display: 'flex' }}>
                    <label className={styles.label} style={{ marginRight: '8px' }} for="fullname">
                      Full name <span style={{ color: "red" }}><b>*</b></span>
                    </label>
                    <Tooltip placement="top" title="Please enter full name and only charecters!">
                      <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                    </Tooltip>
                  </div>
                  <Form.Item
                    id="fullname"
                    // {...formItemLayout}
                    name="fullName"
                    rules={[
                      {
                        required: true,
                        message: "Please enter full name"
                      },
                      {
                        pattern: /^[a-zA-Z\s]*$/,
                        message: '',
                      },
                    ]}
                  >
                    <Input
                      onChange={handleOnChange} placeholder="Full name" disabled={disableFlag} />
                  </Form.Item>
                </div>

              </div>

              <div className="row">
                <div className="col-sm-5" style={{ textAlign: "left", alignItems: 'left' }}>


                  <div style={{ display: 'flex' }}>
                    <label className={styles.label} style={{ marginRight: '8px' }} for="mobile">
                      Mobile <span style={{ color: "red" }}><b>*</b></span>
                    </label>
                    <Tooltip title="Please enter 10 digits mobile number">
                      <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                    </Tooltip>
                  </div>

                  <Form.Item
                    id="mobile"
                    // {...formItemLayout1}
                    name="mobile"
                    rules={[
                      {
                        required: true,
                        message: "Please enter a mobile number"
                      },
                      {
                        pattern: /^[0-9]{10}$/,
                        message: '',
                      },
                    ]}
                  >
                    <Input
                      onChange={handleOnChange}
                      placeholder="Mobile"
                      disabled={disableFlag}
                    />
                  </Form.Item>

                </div>

                <div className="example col-sm-2"></div>


                <div className="col-sm-2">
                  <label className={styles.label} for="city">
                    Gender <span style={{ color: "red" }}><b>*</b></span>
                  </label>

                  <Form.Item
                    id="Gender"
                    // {...formItemLayout2}
                    name="gender"
                    rules={[
                      {
                        required: true,
                        message: '',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Gender"
                      disabled={disableFlag && (globalData?.studetnInfo?.gender !== null && globalData?.studetnInfo?.gender !== 0)}
                      optionFilterProp="children"
                      style={{ textAlign: "left" }}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={handleGenderChange}
                    >
                      <option value={1}>Male</option>
                      <option value={2}>Female</option>
                      <option value={3}>Others</option>

                    </Select>
                  </Form.Item>
                </div>


                <div className="col-sm-3">
                  <label className={styles.label} for="dob">
                    Date of birth
                  </label>
                  <Form.Item
                    id="dob"
                    // {...formItemLayout1}
                    name="dob"
                    rules={[
                      {
                        required: false,
                        message: '',
                      },
                    ]}
                  >
                    <DatePicker
                      onChange={handleOnChange}
                      style={{ width: "-webkit-fill-available" }}
                      placeholder="Date of birth"
                      disabled={disableFlag && globalData?.studetnInfo?.dob !== null}
                      format={dateFormat}
                      disabledDate={handleDisabledDate}
                    />
                  </Form.Item>

                </div>

              </div>

              <div className="row">
                <div className="col-sm-5" style={{ textAlign: "left", alignItems: 'left' }}>
                  <div style={{ display: 'flex' }}>
                    <label className={styles.label} style={{ marginRight: '8px' }} for="passCode">
                      Password <span style={{ color: "red" }}><b>*</b></span>

                    </label>
                    <Tooltip title="Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one number, and one special character">
                      <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                    </Tooltip>
                  </div>
                  <Form.Item
                    id="passCode"
                    // {...formItemLayout}
                    name="passCode"
                    rules={[
                      {
                        required: true,
                        validator: strongValidator,
                        message: 'Password must be at least 8 characters long',
                      },
                      {
                        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=]).{8,}$/,
                        message: '',
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password

                      onChange={handleOnChange}
                      placeholder="Password"
                      disabled={disableFlag}
                    />
                  </Form.Item>
                </div>
                <div className="example col-sm-2"></div>
                <div className="col-sm-5" style={{ textAlign: "left", alignItems: 'left' }}>

                  <div style={{ display: 'flex' }}>
                    <label className={styles.label} style={{ marginRight: '8px' }} for="confirm">
                      Confirm password <span style={{ color: "red" }}><b>*</b></span>
                    </label>
                  </div>

                  <Form.Item
                    id="confirm"
                    // {...formItemLayout}
                    name="confirm"
                    dependencies={["passCode"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: '',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("passCode") === value) {
                            return Promise.resolve();
                          }

                          return Promise.reject(new Error("The confirmed password does not match the original"));
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      onChange={handleOnChange}
                      placeholder="Confirm password"
                      onPaste={(e) => e.preventDefault()}
                      disabled={disableFlag}
                    />
                  </Form.Item>
                </div>

              </div>


              <div className="row">
                <div className="col-sm-5">
                  <label className={styles.label} for="address">
                    Address
                  </label>

                  <Form.Item
                    id="address"
                    // {...formItemLayout1}
                    name="address"
                    rules={[
                      {
                        required: false,
                        message: t("@reqAddress"),
                      },
                    ]}
                  >
                    <Input onChange={handleOnChange} placeholder="Address" disabled={disableFlag && (globalData?.studetnInfo?.address !== '' && globalData?.studetnInfo?.address !== null)} />
                  </Form.Item>
                </div>
                <div className="example col-sm-2"></div>
                <div className="col-sm-5">
                  <label className={styles.label} for="state">
                    State
                  </label>
                  <Form.Item
                    id="state"
                    // {...formItemLayout2}
                    name="state"
                    rules={[
                      {
                        required: false,
                        message: t("@reqState"),
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="State"
                      optionFilterProp="children"
                      //disabled={disableFlag}
                      style={{ textAlign: "left" }}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={handleOnChange}
                      defaultValue={0}
                    >
                      {StatesData &&
                        StatesData.length > 0 &&
                        StatesData?.map((st, index) => {
                          return (
                            <option
                              value={`${StatesData[index].valueInt}`}
                            >{`${StatesData[index].valueString}`}</option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-5">
                  <label className={styles.label} for="city">
                    City
                  </label>

                  <Form.Item
                    id="city"
                    // {...formItemLayout2}
                    name="city"
                    rules={[
                      {
                        required: false,
                        message: t("@reqCity"),
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="City"
                      //disabled={disableFlag}
                      optionFilterProp="children"
                      style={{ textAlign: "left" }}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={handleOnChange}
                    >
                      {CityData &&
                        CityData.length > 0 &&
                        CityData.map((st, index) => {
                          return (
                            <option
                              value={`${CityData[index].valueInt}`}
                            >{`${CityData[index].valueString}`}</option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </div>
                <div className="example col-sm-2"></div>
                <div className="col-sm-5">
                  <label className={styles.label} for="pinCode">
                    Pincode
                  </label>
                  <Form.Item
                    id="pinCode"
                    // {...formItemLayout}
                    name="pinCode"
                    rules={[
                      {
                        required: false,
                        message: t("@reqPincode"),
                      },
                    ]}
                  >
                    <Input onChange={handleOnChange} placeholder="Pincode" />
                  </Form.Item>
                </div>
              </div>

              <Form.Item>
                <div className="row">
                  <div className="col-sm-5" style={{ textAlign: "left", alignItems: 'left' }}>
                    <span style={{ color: "red" }}><b>*</b>Required fields</span>
                  </div>
                  <div className="example col-sm-2"></div>
                  <div className="col-sm-5">
                    <Button
                      style={{
                        backgroundColor: "#EC9706",
                        color: "#ffff",
                        marginBottom: "22px",
                        float: "right",
                        paddingInline: "5%",
                        border: "none",
                      }}
                      htmlType="submit"
                    >
                      {t("nextBtn")}
                    </Button>{" "}
                  </div>
                </div>
              </Form.Item>


              <div>
                <h4 textAlign='center'>OR</h4>
              </div>

              <Form.Item>
                <Button
                  style={{
                    marginBottom: "5px",
                    float: "center",
                    align: 'center',
                    border: "none",
                    paddingInline: "5%",
                  }}
                >
                  <GoogleOAuthProvider clientId="233834340339-695vkqrle4p424vl6sspaj5t716l3246.apps.googleusercontent.com">
                    <Google onClick={(email, auth, newUser) => onGoogleAuthClick(email, auth, newUser)} providerId={providerIdByUrl} />
                  </GoogleOAuthProvider>
                </Button>
              </Form.Item>

              {/*<Form.Item>
                <br />  */}
              {/* {t("haveAccount")}{" "}
                  <a style={{ color: "#FF802B" }} href="/">
                    {t("@signIn")}
                  </a> */}
              {/*</Form.Item>  */}


            </Form>
          ) : (
            <Form onFinish={(values) => onSignUp(values)}>

              <div className="row" >
                <div className="col-sm-5">
                  <label className={styles.label} for="Language">
                    Language
                  </label>

                  <Form.Item
                    {...formItemLayout}
                    name="Language"
                    rules={[
                      {
                        required: false,
                        message: t("@reqLanguage"),
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      style={{ textAlign: "left" }}
                      placeholder="Language"
                      //disabled={disableFlag}
                      optionFilterProp="children"
                      //   onChange={onChange}
                      //   onSearch={onSearch}
                      //   defaultValue={props.LeaveTypes}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {LanguageData &&
                        LanguageData.length > 0 &&
                        LanguageData.map((st, index) => {
                          return (
                            <option
                              value={`${LanguageData[index].valueInt}`}
                            >{`${LanguageData[index].valueString}`}</option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-sm-2"></div>
                <div className="col-sm-5">
                  <label className={styles.label} for="Board">
                    Education Board
                  </label>

                  <Form.Item
                    {...formItemLayout}
                    name="Board"
                    rules={[
                      {
                        required: false,
                        message: t("@reqBoard"),
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      style={{ textAlign: "left" }}
                      placeholder="Board"
                      //disabled={disableFlag}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    // options={[
                    //   {
                    //     value: "state",
                    //     label: "State Board",
                    //   },
                    //   {
                    //     value: "CBSC",
                    //     label: "C.B.S.C",
                    //   },
                    // ]}
                    >
                      {EduboardData &&
                        EduboardData.length > 0 &&
                        EduboardData.map((st, index) => {
                          return (
                            <option
                              value={`${EduboardData[index].valueInt}`}
                            >{`${EduboardData[index].valueString}`}</option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-5">
                  <label className={styles.label} for="Class">
                    Class
                  </label>

                  <Form.Item
                    {...formItemLayout}
                    name="Class"
                    rules={[
                      {
                        required: false,
                        message: t("@reqClass"),
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      style={{ textAlign: "left" }}
                      placeholder="Class"
                      //disabled={disableFlag}
                      optionFilterProp="children"
                      //   onChange={onChange}
                      //   onSearch={onSearch}
                      //   defaultValue={props.LeaveTypes}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {ClassesdData &&
                        ClassesdData.length > 0 &&
                        ClassesdData.map((st, index) => {
                          return (
                            <option
                              value={`${ClassesdData[index].valueInt}`}
                            >{`${ClassesdData[index].valueString}`}</option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-sm-2"> </div>
                <div className="col-sm-5">
                  <label className={styles.label} for="Interest">
                    Interest
                  </label>

                  <Form.Item
                    {...formItemLayout}
                    name="Interest"
                    rules={[
                      {
                        required: false,
                        message: "",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      style={{ textAlign: "left" }}
                      placeholder="Interest"
                      //disabled={disableFlag}
                      optionFilterProp="children"
                      //   onChange={onChange}
                      //   onSearch={onSearch}
                      //   defaultValue={props.LeaveTypes}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >

                      {InterestData &&
                        InterestData.length > 0 &&
                        InterestData.map((st, index) => {
                          return (
                            <option
                              value={`${InterestData[index].interestId}`}
                            >{`${InterestData[index].interests}`}</option>
                          );
                        })}
                      {/*  <option
                            value={`${InterestData}`}
                          >{`${InterestData}`}</option>
                          */}
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="row">
                <div className="col-sm">
                  {/* <h3>{t("interests")}</h3>*/}
                  <div style={{ margin: "170px" }}>
                  </div>


                  {/* <small aria-disabled>You can always change these later.</small> */}

                </div>
                <div className="col-sm">
                </div><div className="col-sm">
                </div>
              </div>



              <Form.Item>
                <div style={{ margin: "37px" }} className="row">

                </div>
                <Button
                  style={{
                    backgroundColor: "#EC9706",
                    color: "#ffff",
                    marginBottom: "22px",
                    float: "right",
                    paddingInline: "3%",
                    border: "none",
                  }}
                  htmlType="submit"
                >
                  Submit
                </Button>{" "}
                <br />
                {/* <a style={{ color: "#FF802B" ,margin:'5px' }} onClick={onPrev}>
                    {t("prevBtn")}
                  </a> */}
                <br />
                {/* {t("haveAccount")}{" "}
                  <a style={{ color: "#FF802B" }} href="/">
                    {t("@signIn")}
                  </a> */}
              </Form.Item>

            </Form>
          )}
          <Modal
            title="Confirmation"
            open={confirmVisible}
            //open={true}
            onCancel={() => setConfirmVisible(false)}
            //onOk={() => setConfirmVisible(false)}
            footer={[
              <Button
                style={{
                  backgroundColor: "#EC9706",
                  color: "#ffff",
                }}
                key="ok"
                type="primary"
                onClick={() => setConfirmVisible(false)}
              >
                OK
              </Button>
            ]}
          >
            This user is already registered in the application, relevant information will be populated automatically.
            <br />
            Please confirm the user is same and add.
          </Modal>
        </div>
      </div>

      {/* </Card> */}
    </div >
  );
};

export default SignInup;
