import axios from "axios";
import CryptoJS from 'crypto-js';
import { configUrl } from "../config";

export async function onNextService(studetnInfo) {
  console.log(studetnInfo, 'onNextPayload');
  const key = 'pj23vs7nycq18uew';
  let secretKey = CryptoJS.enc.Utf8.parse(key);

  const encryptPassword = (password, secretKey) => {

    let encryptedBytes = CryptoJS.AES.encrypt(password, secretKey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    let encryptedString = encryptedBytes.toString();

    console.log(encryptedString, 'encryptedone');
    return encryptedString;
    // return CryptoJS.AES.encrypt(password, secretKey).toString();
  }
  const decryptPassword = (encryptedPassword, secretKey) => {

    let decryptedBytes = CryptoJS.AES.decrypt(encryptedPassword, secretKey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    let decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);

    console.log(decryptedText, 'decryptedone');
    return decryptedText;
    // const bytes = CryptoJS.AES.decrypt(encryptedPassword, secretKey);
    // return bytes.toString(CryptoJS.enc.Utf8);
  }
  studetnInfo.passCode = encryptPassword(studetnInfo?.passCode, secretKey)
  studetnInfo.confirm = encryptPassword(studetnInfo?.confirm, secretKey)
  return axios
    .post(
      `${configUrl.AssessmentServer}/api/student/studentRegistration`, studetnInfo
      // `${configUrl.AssessmentServer}/api/DemoghraphicInformation/getAllDemographicInformaionKeys`
    )
    .then((respose) => {
      console.log(respose.data, 'dataVals');
      respose.data.passCode = decryptPassword(respose.data?.passCode, secretKey)
      return respose.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
export async function onSignUpService(studetnInfo) {
  console.log(studetnInfo, 'studentInfo123');

  return axios
    .post(
      `${configUrl.AssessmentServer}/api/Student/addEducationInformation`, studetnInfo
      // `${configUrl.AssessmentServer}/api/DemoghraphicInformation/getAllDemographicInformaionKeys`
    )
    .then((respose) => {
      console.log(respose.data.$values);
      return respose.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
export async function getstudentData(userId) {
  console.log("get data for update", userId);
  return axios
    .get(
      `${configUrl.AssessmentServer}/api/Student/getStudentDetail/${userId}`
    )
    .then((respose) => {
      console.log(respose.data.$values);
      return respose.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}
export async function geteducationData(userId) {
  console.log("get data for update", userId);
  return axios
    .get(
      `${configUrl.AssessmentServer}/api/Student/getEducationInfo/${userId}`
    )
    .then((respose) => {
      console.log(respose.data.$values);
      return respose.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
export async function getUpdateData() {
  console.log("update Data");
  return axios
    .put(
      `${configUrl.AssessmentServer}api/Student/updateStudentDetails`
      // `${configUrl.AssessmentServer}/api/DemoghraphicInformation/getAllDemographicInformaionKeys`
    )
    .then((respose) => {
      console.log(respose.data.$values);
      return respose.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
export async function getparentData(userId) {
  console.log("get data for update", userId);
  return axios
    .get(
      `${configUrl.AssessmentServer}/api/Student/getStudentDetail/${userId}`
    )
    .then((respose) => {
      console.log(respose.data.$values);
      return respose.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
