import axios from "axios";
import { configUrl } from "../../config";

export default async function UseGetNotificationDescription(id) {

  return axios.get(`${configUrl.AssessmentServer}/api/Counselling/getNotification/${id}`)
    .then((response) => {
      console.log("onClickNotification", response.data);
      // BSS121
      return response.data;

    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
