import axios from "axios";
import CryptoJS from 'crypto-js';
import { configUrl } from "../config";

const key = 'pj23vs7nycq18uew';
let secretKey = CryptoJS.enc.Utf8.parse(key);

const encryptPassword = (password, secretKey) => {

    let encryptedBytes = CryptoJS.AES.encrypt(password, secretKey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    let encryptedString = encryptedBytes.toString();

    console.log(encryptedString, 'encryptedone');
    return encryptedString;
    // return CryptoJS.AES.encrypt(password, secretKey).toString();
}
const createUser = async (user) => {
    const payload = {
        userId: user.userId,
        userName: user.userName,
        passCode: encryptPassword(user.passCode, secretKey),
        firstName: user.firstName,
        lastName: user.lastName,
        fullName: user.fullName,
        email: user.email,
        mobile: user.mobile,
        address: user.address,
        dob: user.dob,
        doa: user.doa,
        doj: user.doj,
        dol: user.dol,
        isActive: user.isActive,
        isSso: user.isSso,
        ssoprovider: user.ssoprovider,
        avatar: user.avatar,
        userTypeId: user.userTypeId,
        createdBy: user.createdBy,
        createdDate: user.createdDate,
        updatedBy: user.updatedBy,
        updatedDate: new Date(),
        providerId: user.providerId
    };
    let response = await axios.post(`${configUrl.AssessmentServer}/api/User/5`, payload)
    return response.data
};

const updateUser = async (user) => {
    const payload = {
        userId: user.userId,
        userName: user.userName,
        passCode: encryptPassword(user.passCode, secretKey),
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        mobileNo: user.mobileNo,
        addressLine: user.addressLine,
        dob: user.dob,
        doa: user.doa,
        doj: user.doj,
        dol: user.dol,
        active: user.active,
        isSso: user.isSso,
        ssoprovider: user.ssoprovider,
        avatar: user.avatar,
        userTypeId: user.userTypeId,
        createdBy: user.createdBy,
        createdDate: user.createdDate,
        updatedBy: user.updatedBy,
        updatedDate: user.updatedDate,
        providerId: user.providerId
    };

    let response = await axios.put(`${configUrl.AssessmentServer}/api/User/${user.userId}`, payload)

    return response.data
}

const getUserByUserName = async (userName) => {
    let response = await axios.get(`${configUrl.AssessmentServer}/api/User/Name/${userName}`)
    return response.data
}

const checkIfUserExists = (userName) => {

    axios.get(`${configUrl.AssessmentServer}/api/User/Name/${userName}`)
        .then(response => {
            // Handle response
            console.log('response log: ', response.data);
            if (response.data.email == userName) {
                return 'NA 1';
            }
        })
        .catch(err => {
            // Handle errors

            console.log('response log code: ', err.response.data.status);
            return 'NA2';
        });
    console.log('1 not found the user')
    return 'NA 3';
}

export default {
    createUser: createUser,
    updateUser: updateUser,
    getUserByUserName: getUserByUserName,
    checkIfUserExists: checkIfUserExists
}
