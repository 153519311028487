import { Box } from '@mui/material';
import 'katex/dist/katex.min.css';
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from 'react-i18next';
import Latex from 'react-latex-next';
import styles from '../../styles/solution.module.css';
{/* latex CSS */ }

export default function Solution({ question, contentType, updateQuestion, preview, questionObj }) {
  const { t } = useTranslation();
  console.log(Solution, "solution")
  return (
    <div>
      <Box p={2} >
        <h3>{t('solution')}: </h3>

        <Box p={1} style={{ color: 'black' }}>
          {
            contentType === 1 ?
              /* Text Based */
              <h4>{questionObj[0]?.SolutionText && questionObj[0]?.SolutionText}</h4>
              :
              contentType === 3 ?
                /* latex Based */
                (questionObj[0]?.SolutionText == null ? '' :
                  <h4><Latex>{questionObj[0]?.SolutionText}</Latex></h4>)
                :
                contentType === 4 ?
                  /* for mathMl need to modify with html parser */
                  (questionObj[0]?.SolutionText == null ? '' :
                    <div style={{ color: 'black' }}>{ReactHtmlParser(questionObj[0]?.SolutionText)}</div>)
                  :
                  <h4>{questionObj[0]?.SolutionText && questionObj[0]?.SolutionText}</h4>
          }
        </Box>
        <Box p={1}>
          {questionObj[0]?.Solution && <img src={questionObj[0]?.Solution} alt="..." className={styles.Solutionimage} />}
        </Box>
        {
          questionObj[0] && questionObj[0]?.SolutionText == null && questionObj[0]?.Solution == null && (<h4 style={{ color: 'black' }}>{t('SolutionNA')}</h4>)
        }
      </Box>
    </div>
  )
}
