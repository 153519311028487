

import { useTheme } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import { Grid, useMediaQuery } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import Button from '../Components/CustomButtons/Button';
import styles from '../styles/DetailingTable.module.css';


const TreeHierarchy = ({ analyticsData, pieChartAnalyticsData }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));


    const DataPieChartTable = (data) => {
        console.log(data, 'data1235')
        return (
            <table style={{ border: 'solid 1px #FC940C' }}>
                <thead style={{ background: '#f5910dd1', color: 'white' }}>
                    <tr>
                        {/* <th>ID</th> */}
                        <th style={{ padding: '0px 0px 0px 0px', textAlign: 'center' }}>{t("total")}</th>
                        <th style={{ padding: '0px 0px 0px 0px', textAlign: 'center' }}>{t("unattempted")}</th>
                        <th style={{ padding: '0px 0px 0px 0px', textAlign: 'center' }}>{t("attempted")}</th>
                        <th style={{ padding: '0px 0px 0px 0px', textAlign: 'center' }}>{t("correct")}</th>
                        <th style={{ padding: '0px 0px 0px 0px', textAlign: 'center' }}>{t("incorrect")}</th>
                    </tr>
                </thead>
                <tbody>
                    {console.log("dataaka", data, data?.data)}
                    {data?.data ?
                        <tr >
                            <td style={{ padding: '0px 0px 0px 0px', textAlign: 'center' }}>{data?.data?.totalQuestions}</td>
                            <td style={{ padding: '0px 0px 0px 0px', textAlign: 'center' }}>{data?.data?.unattempted}</td>
                            <td style={{ padding: '0px 0px 0px 0px', textAlign: 'center' }}>{data?.data?.correct + data?.data?.incorrect}</td>
                            <td style={{ padding: '0px 0px 0px 0px', textAlign: 'center' }}>{data?.data?.correct}</td>
                            <td style={{ padding: '0px 0px 0px 0px', textAlign: 'center' }}>{data?.data?.incorrect}</td>
                        </tr>
                        : <tr>
                            <td colspan="5" style={{ textalign: 'center' }}>
                                <h6 >{t("norecord")}</h6>
                            </td>
                        </tr>

                    }
                </tbody>
            </table>
        );
    };
    console.log("datapie", pieChartAnalyticsData);
    const TreeNode = ({ node, packageId }) => {
        console.log("renderTree1", node)
        console.log("renderTree Name", node?.Name)
        console.log("renderTree data", node?.CorrectQuestionCount)

        return (
            <TreeItem
                key={node.Guid}
                nodeId={node.Guid}
                // label={
                //     <div className={styles.treeNode}>
                //         <div className={styles.treeNodeText}>
                //             <strong>{node.Name}:</strong> Total Questions: {node.TotalQuestions}, Attempted: {node.Attempted}, Correct: {node.Correct}, Incorrect: {node.Incorrect}
                //         </div>
                //         <div className={styles.treeNodeButtons}>
                //             <button className={styles.button} style={{ backgroundColor: '#4C8055' }}>
                //                 Total Questions
                //             </button>
                //             <button className={styles.button} style={{ backgroundColor: '#9da39d' }}>
                //                 Attempted
                //             </button>
                //             <button className={styles.button} style={{ backgroundColor: '#34C0D3' }}>
                //                 Correct
                //             </button>
                //             <button className={styles.button} style={{ backgroundColor: '#34C0D3' }}>
                //                 Incorrect
                //             </button>
                //         </div>
                //     </div>
                // }

                label={
                    <div>
                        <div xs={6} sm={6} md={4}>
                            <p style={{ float: 'left', marginTop: '19px', fontSize: '14px' }}>

                                {node?.TopicName}{" "}

                            </p>
                        </div>
                        <div xs={6} sm={6} md={8} style={{ float: 'right' }}>

                            <Button
                                size="sm"
                                justIcon
                                round
                                // color="behance"
                                style={{ float: 'left', marginInline: '16px', backgroundColor: '#ffa726', height: '44px', width: '47px', fontSize: '14px' }}
                            >
                                {
                                    node.Label = node?.AssignedQuestionCount
                                }

                            </Button>

                            <Button
                                size="sm"
                                justIcon
                                round
                                style={isMobile ? { float: 'initial', backgroundColor: '#34C0D3', height: '44px', width: '47px', fontSize: '14px' } : { float: 'initial', marginInline: '16px', backgroundColor: '#34C0D3', height: '44px', width: '47px', fontSize: '14px' }}
                            >
                                {
                                    node.Label = node?.AttemptedQuestionCount

                                }

                            </Button>
                            <Button
                                size="sm"
                                justIcon
                                round
                                // color="primary"
                                style={{ float: 'initial', marginInline: '16px', backgroundColor: '#4C8055', height: '44px', width: '47px', fontSize: '14px' }}
                            >
                                {
                                    node.Label = node?.CorrectQuestionCount
                                }

                            </Button>
                            <Button
                                size="sm"
                                justIcon
                                round
                                style={isMobile ? { float: 'right', backgroundColor: '#EC192F', height: '44px', width: '47px', fontSize: '14px' } : { float: 'right', marginInline: '16px', backgroundColor: '#EC192F', height: '44px', width: '47px', fontSize: '14px' }}
                            >
                                {
                                    node.Label = node?.IncorrectQuestionCount

                                }

                            </Button>
                        </div>
                    </div>
                }
            >
                {Array.isArray(node.SubObjectList) && node.SubObjectList.length > 0
                    ? node.SubObjectList.map((subNode) => (
                        <TreeNode key={subNode.Guid} node={subNode} />
                    ))
                    : null}
            </TreeItem>
        );
    };


    return (
        <>
            <h4 className={styles.tablePieHeirarchyHeading} style={{ marginLeft: '4%' }}><b>{t("coverage")}</b></h4>
            <Grid container spacing={2} style={{ justifyContent: 'center' }}>
                <Grid item xs={12} sm={12} md={6} style={{ paddingInline: '20px' }}>
                    <DataPieChartTable data={pieChartAnalyticsData} />
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                </Grid>
            </Grid>
            {/* <GridItem xs={6} sm={6} md={6} style={{ marginLeft: '3px', marginRight: '3px' }}> */}
            {/* </GridItem> */}
            <div className={styles.treeContainer} >
                <h4 className={styles.tablePieHeirarchyHeading}><b>{t("coverageDetails")}</b></h4>
                <div>
                    <div xs={6} sm={6} md={8} style={{ float: 'right' }}>
                        <p className={styles.tableHeadingPiHeirarchy} >{t("total")}  </p>

                        <p className={styles.tableHeadingPiHeirarchy} >{t("attempted")}  </p>

                        <p className={styles.tableHeadingPiHeirarchy} >{t("correct")}   </p>
                        <p className={styles.tableHeadingPiHeirarchy} >{t("incorrect")}   </p>

                    </div>
                </div>
                <TreeView
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    sx={{
                        height: 264,
                        flexGrow: 1,
                        maxWidth: 1000,
                        overflowY: 'false',
                    }}
                    disableSelection={true}
                >
                    {analyticsData?.map((node) => (
                        <TreeNode key={node.Guid} node={node} />
                    ))}
                </TreeView>
            </div>
        </>
    );
};

export default TreeHierarchy;
