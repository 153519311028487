import { InfoCircleOutlined } from '@ant-design/icons';
import { CircularProgress } from "@material-ui/core";
import { Alert, Snackbar } from "@mui/material";
import { Button, Form, Input, Tooltip } from "antd";
import CryptoJS from 'crypto-js';
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Card from "../Card/Card";
import { LoginContext } from "../contexts/LoginContext";
import styles from "../styles/signin.module.css";
import GridContainer from "./Grid/GridContainer";
import GridItem from "./Grid/GridItem";

import 'react-toastify/dist/ReactToastify.css';
import resetPassService from '../services/resetPassService';

const ForgotPassword = () => {
    const location = useLocation();
    const formItemLayout = {
        labelCol: {
            span: 6,
        },
        wrapperCol: {
            span: 15,
        },
    };
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loginUsername, setLoginUsername] = React.useState("");
    const [loginPassword, setLoginPassword] = React.useState("");
    const [confirmloginPassword, setConfirmLoginPassword] = React.useState("");
    const [retry, setRetry] = React.useState(true);
    const [showAlert, setShowAlert] = React.useState(false);
    const [UserDetails, setUserDetailsData] = useState({});
    const [open, setOpen] = React.useState(false);
    const [severty, setSeverty] = React.useState('success');
    const [alertMessage, setAlertMessage] = React.useState('');
    const [googleAuth, setGoogleAuth] = React.useState(false);
    const { userId, reqDateTime } = useParams();
    console.log(reqDateTime, 'reqDate1')
    const [providerIdByUrl, setProviderIdByUrl] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const { setUserName, UserName, setProviderKey, providerKey, setProviderName, setEmailC, setProviderId, setUserId, setUserDetail, userDetail, setUserType, siblingDetail, setSiblingDetail, setEducationDetails, setPageKey } =
        useContext(LoginContext);

    const key = 'pj23vs7nycq18uew';
    let secretKey = CryptoJS.enc.Utf8.parse(key);

    const encryptPassword = (password, secretKey) => {

        let encryptedBytes = CryptoJS.AES.encrypt(password, secretKey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
        let encryptedString = encryptedBytes.toString();

        console.log(encryptedString, 'encryptedone');
        return encryptedString;
        // return CryptoJS.AES.encrypt(password, secretKey).toString();
    }
    const decryptPassword = (encryptedPassword, secretKey) => {

        let decryptedBytes = CryptoJS.AES.decrypt(encryptedPassword, secretKey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
        let decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);

        console.log(decryptedText, 'decryptedone');
        return decryptedText;
        // const bytes = CryptoJS.AES.decrypt(encryptedPassword, secretKey);
        // return bytes.toString(CryptoJS.enc.Utf8);
    }
    // console.log(decryptPassword(reqDateTime, secretKey), "reqDate is:")
    const handlePassword = (e) => {
        setLoginPassword(e.target.value);
    };
    const handleConfirmPassword = (e) => {
        setConfirmLoginPassword(e.target.value);
    };

    console.log(providerIdByUrl, "providerIds");
    const onSubmit = async (values) => {
        console.log(values, 'values1')
        console.log(loginPassword, confirmloginPassword, userId, 'logPassConf')
        setIsLoading(true);
        let ForgotResp = await resetPassService.ForgotPassService(loginPassword, confirmloginPassword, userId);
        setIsLoading(false);
        console.log(ForgotResp, 'forgotResp')
        if (ForgotResp.status == 200) {
            console.log(ForgotResp.data)
            setOpen(true);
            setSeverty('success');
            setAlertMessage(`${ForgotResp?.data}`)
            setTimeout(() => {
                setOpen(false);
                navigate(`/${providerKey}`);
            }, 3000);
        } else {
            setOpen(true);
            setSeverty('error');
            setAlertMessage(`${ForgotPassword?.messege}`)
        }
        // let loginInfo = {}
        // loginInfo.username = loginUsername
        // loginInfo.providerId = providerIdByUrl;
        // console.log(providerIdByUrl, "providerIds", loginInfo);
        // getUserDetails(loginInfo);
    };
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };
    const strongValidator = (rule, value, callback) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=])/;
        if (!value.match(regex)) {
            return callback(true);
        }
        callback();
    };

    const [expirationTime, setExpirationTime] = useState(null);
    const [isExpired, setIsExpired] = useState(false);

    useEffect(() => {

        const url = location.pathname;
        console.log(url, 'urlis:')
        // Spliting URL by '/'
        const segments = url.split('/');
        console.log(segments, 'segments')
        // Removing first two elements (indexes 0 and 1)
        const restOfParams = segments.slice(3);
        console.log(restOfParams, 'respParams')
        // Join the remaining segments back into a string
        const restOfParamsString = restOfParams.join('/');

        console.log(restOfParamsString, 'lastParams1')
        const givenTime = decryptPassword(restOfParamsString, secretKey);


        const parsedTime = new Date(givenTime);
        // adding expiring hours can replace accordingly
        let exprireHours = 12;
        // Adding 1 hour to the parsed time
        parsedTime.setHours(parsedTime.getHours() + exprireHours);


        setExpirationTime(parsedTime);
        console.log(givenTime, 'givenTime')
        console.log(parsedTime, 'parsedTime123')
        // Check if link is expired
        const interval = setInterval(() => {
            const currentTime = new Date();
            setIsExpired(currentTime > parsedTime);
        }, 1000); // Check every second

        // Clear interval on component unmount
        return () => clearInterval(interval);
    }, []);

    return (
        <div className={styles.cardRow}>
            <GridContainer justifyContent="center">
                <GridItem xs={12} sm={6} md={3} style={{ marginInline: "11px" }}>
                    <Card
                        style={{
                            padding: "11px 11px 0px",
                            zIndex: "9",
                            boxShadow: "10px 16px 28px #000000",
                        }}
                    >
                        {
                            isExpired ?
                                <h4 style={{ color: 'red', fontWeight: 'lighter' }} className={styles.Sheading}>{"Link is expired !"}</h4>
                                :
                                <h4 className={styles.Sheading}>{t("Reset Password")}</h4>

                        }
                        {/* <h4 className={styles.Sheading}>{t("Reset Password")}</h4> */}
                        <Form
                            name="normal_login"
                            // className={styles.responsive}
                            onFinish={(values) => onSubmit(values)}
                            onFinishFailed={({ values, errorFields, outOfDate }) => { }}
                        >
                            {/* <label className={styles.label} htmlFor="email">
                                {t("@email")}
                            </label>
                            <Form.Item
                                id="email"
                                // {...formItemLayout}
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: t("reqUsername"),
                                    },
                                ]}
                            >
                                <Input
                                    onChange={handleUserName}
                                    placeholder="Enter your email Id"
                                />
                            </Form.Item> */}

                            <label className={styles.label} htmlFor="password">
                                {t("@pass")} <Tooltip title={t("@reqPasswrd")}>
                                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)', marginInline: '2%', paddingTop: '1%' }} />
                                </Tooltip>
                            </label>
                            {/* <Tooltip title="Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one number, and one special character">
                                <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                            </Tooltip> */}
                            <Form.Item
                                id="password"
                                // {...formItemLayout}
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        validator: strongValidator,
                                        message: 'Password must be at least 8 characters long',
                                    },
                                    {
                                        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=]).{8,}$/,
                                        message: '',
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input.Password
                                    onChange={handlePassword}
                                    placeholder="Enter your password"
                                    minLength={4}
                                    disabled={isExpired}
                                />
                            </Form.Item>

                            <label className={styles.label} htmlFor="confirmPassword">
                                {t("@confirmPass")}
                            </label>
                            <Form.Item
                                id="confirmPassword"
                                name="confirmPassword"
                                dependencies={["password"]}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: '',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue("password") === value) {
                                                return Promise.resolve();
                                            }

                                            return Promise.reject(new Error("The confirmed password does not match the original"));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password
                                    onChange={handleConfirmPassword}
                                    placeholder="Confirm password"
                                    onPaste={(e) => e.preventDefault()}
                                    disabled={isExpired}
                                />
                            </Form.Item>

                            {/* <Form.Item
                                // {...formItemLayout}
                                className={styles.minControls}
                            >
                                <Checkbox className={styles.rememberMe}>
                                    {t("rememberme")}
                                </Checkbox>
                                <a className={styles.forgotPass} href="/forgotPass">
                                    {t("forgotPass")}
                                </a>
                            </Form.Item> */}
                            <Form.Item
                            // {...formItemLayout}
                            >
                                {isLoading == true ? (
                                    <CircularProgress
                                        style={{ size: "1rem", color: "#FF802B" }}
                                    />
                                ) : (
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className={styles.loginButton}
                                        style={{ backgroundColor: "#FF802B" }}
                                        disabled={isExpired}
                                    >
                                        {t("saveBtn")}
                                    </Button>
                                )}
                            </Form.Item>
                            {/* <Form.Item>
                                <h4 className={styles.Hline}>Or</h4>
                                <br />
                                

                                <div className={styles.flotingButtons}>
                                    <Button>
                                        <GoogleOAuthProvider clientId="233834340339-695vkqrle4p424vl6sspaj5t716l3246.apps.googleusercontent.com">
                                            <Google
                                                onClick={(email, auth, newUser) =>
                                                    onGoogleAuthClick(email, auth, newUser, providerIdByUrl)
                                                }
                                                providerId={providerIdByUrl}
                                            />
                                        </GoogleOAuthProvider>
                                    </Button>
                                </div>
                                <small className={styles.toSignUp}>
                                    {t("backToLogin")}
                                    <a
                                        style={{ color: "#FF802B", marginLeft: "5px" }}
                                        href={`/signIn/${id}`}
                                    >
                                        {t("@signIn")}
                                    </a>
                                </small>
                                <br />
                                <select
                                    style={{ margin: "23px 18px 5px 22px" }}
                                    className="selectLanguage"
                                    value={i18next.language}
                                    onChange={(e) => changeLang(e.target.value)}
                                >
                                    <option value="en">English</option>
                                    <option value="mr">मराठी</option>
                                </select>
                            </Form.Item> */}
                        </Form>
                    </Card>
                </GridItem>
            </GridContainer>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={open}
                onClose={handleClose}
                autoHideDuration={3000}
            >
                <Alert
                    severity={severty}
                    onClose={handleClose}
                    sx={{ fontSize: "15px", width: "100%" }}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default ForgotPassword;
