
import axios from "axios";
import { useContext } from "react";
import { useQuery } from "react-query";
import { configUrl } from "../config";
import { LoginContext } from "../contexts/LoginContext";

export default function useGetAssignedTestsDetails(courseId) {
  const { emailC } = useContext(LoginContext);
  // let courseId = 0;
  return useQuery(["getAssignedTestsDetails"], async () => {
    let response = await axios.get(
      `${configUrl.AssessmentServer}/api/Tests/getAssignedTestsDetails/${emailC}/${courseId}`
    );

    return response.data["$values"];
  },
    {
      staleTime: 1000 * 60 * 5,
    });
}
