import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import HttpApi from "i18next-http-backend";
import ten from './locales/en/translation.json';
import tmr from './locales/mr/translation.json';
// "Inline" English and Marathi translations.

// We can localize to any language and any number of languages.

const resources = {
  en: {
    translation: ten,
  },
  mr: {
    translation: tmr,
  },
};

i18next

  .use(initReactI18next)
  .use(HttpApi)
  .init({
    resources,

    lng: "en",

    interpolation: {
      escapeValue: false,
    },

  });
// i18n.language
i18next.changeLanguage("en")
// i18n.language

export default i18next;
