import axios from 'axios';
import { getMessaging, getToken } from "firebase/messaging";
import React, { useContext } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { configUrl } from '../config';
import { LoginContext } from '../contexts/LoginContext';
import app from '../firebase';
import getPrivilegeService from '../services/getPrivilegeService';
import { getSiblingData, getStudentDetail } from '../services/parentService';
import CustomSelect from './CustomSelect/CustomSelect';

export default function SwitchProvider(props) {
    const { providerlist } = props;
    console.log(providerlist, 'providerlist11')
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { setProviderName, setProviderId, setPackageId, setUserPrivileges, setPageKey, setUserId, setSiblingDetail, setUserDetail, setEducationDetails } = useContext(LoginContext);

    const messaging = getMessaging(app);
    const getData = async (userId) => {
        if (userId) {
            const getSiblingDta = await getSiblingData(userId);
            setSiblingDetail(getSiblingDta.$values[0]);
            const StudentDetail = await getStudentDetail(userId);
            setUserDetail(StudentDetail?.studetnInfo);
            setEducationDetails(StudentDetail?.educationInfo);

            console.log(StudentDetail, "getStudentDetail123");
        }
    };
    const activateMessages = async (userId) => {
        console.log('messaging', messaging);
        const token = await getToken(messaging, {
            vapidKey: 'BFLHmJUchm5llz8UsotJ8ztrq30QWeATAIwEb-Oxl2lEjztAG1qYRWXxkccoQJfLGY2vVbYj0NuQHdUteFT0570'
        }).catch(error => console.log("error generatin token"))

        if (token) {
            console.log("After Login token", token);
            const payload = {
                userId: userId[0],
                fcmToken: token
            }
            let response = await axios.post(`${configUrl.AssessmentServer}/api/Counselling/setFCMKey`, payload)

            return response.data
        }
        else if (!token) {
            console.log("no token")

        }
    }
    const selectedProvider = async (v, x) => {
        console.log(v, 'isV', x, 'isX')
        // setAttemptId(v);
        // console.log(providerIdByUrl, "check me again");
        const privilegesDetails = await getPrivilegeService.getPrivileges(x.map((x) => x.userId));
        console.log(privilegesDetails, 'privilegesDetails123')

        // let privileges = loginDetails?.privilegeObject ? JSON.parse(loginDetails?.privilegeObject) : "";
        setProviderId(v);
        activateMessages(x.map((x) => x.userId))
        getData(x.map((x) => x.userId))
        setUserId(x.map((x) => x.userId));
        setProviderName(x.map((x) => x.providerName));
        axios.defaults.headers.common = {
            providerId: v,
        };
        // if (sessionStorage.getItem('login') == true) {
        //     setPageKey(1);
        // } else {
        setUserPrivileges(privilegesDetails);
        setPackageId(privilegesDetails.packageId);
        sessionStorage.setItem("login", true);
        console.log("navigatingggg  from signup");
        navigate("/Home");
        // }

    }
    return (
        <div style={{ padding: '7% 5% 5% 5%', height: 100 }}>
            {
                providerlist.length === 1 ?
                    selectedProvider(providerlist[0]?.id, providerlist.filter((x) => x.id == providerlist[0]?.id)) :
                    <CustomSelect
                        options={providerlist.map((x) => ({ id: x.id, name: x.providerName }))}
                        value={1}
                        setvalue={(v) => {
                            console.log(v, 'vvvkkk');
                            selectedProvider(v, providerlist.filter((x) => x.id == v));
                        }}
                        label={t('selectProvider')}
                    />

            }
        </div>
    )
}
