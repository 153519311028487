// apiService.js
import axios from 'axios';
import { configUrl } from "../../config";

export async function UseGetPieCharAnalytics(studentId) {
    try {
        const response = await axios.get(`${configUrl.AssessmentServer}/api/SwotAnalytics/getPieChartAnalytics/${studentId}`);
        console.log("ajcnaojca45", response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export async function UseGetPieChartHeirarchy(studentId) {
    try {
        const response = await axios.get(`${configUrl.AssessmentServer}/api/SwotAnalytics/getStudentsQuestionCoverage/${studentId}`);
        console.log("PieChartheirarcyData", response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};
