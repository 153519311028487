import React, { useState } from 'react';
import Chart from "react-apexcharts";
import { useTranslation } from 'react-i18next';
import styles from "../styles/Charts.modal.css";

export default function Charts({ analyticsData }) {

  const { t } = useTranslation();
  console.log("graphTableC", analyticsData)
  const [data, setData] = useState(analyticsData[0])
  const [options, setOptions] = useState({
    chart: {
      id: "basic-bar",
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '25%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      categories: []
    },
    noData: {
      text: "Data not found",
      align: "center",
      verticalAlign: "middle",
      style: {
        color: '#aaaaaa',
        background: 'transparent',
      },
    },
  })
  const [series, setSeries] = useState([
    {
      name: "Your Score",
      data: []
    }
  ])

  // const { analytics } = useGetAnalytics(typeId, studentId, lOid);
  React.useEffect(() => {
    var length = Object.keys(analyticsData).length;
    console.log("length in chart", length)

    const TotalScore = [];
    const TotalAttemptScore = [];
    const PositiveScore = [];
    const NegativeScore = [];
    const categoryName = [];

    for (let a = 0; a < length; a++) {
      console.log(analyticsData[a], 'analyticsData')

      TotalScore.push(analyticsData[a].Your)
      // TotalScore.push(analyticsData[a].Average)
      // TotalScore.push(analyticsData[a].Top)

      TotalAttemptScore.push(analyticsData[a].YourTotalAttempt)
      // TotalAttemptScore.push(analyticsData[a].AverageTotalAttempt)
      // TotalAttemptScore.push(analyticsData[a].TopTotalAttempt)

      PositiveScore.push(analyticsData[a].YourPositive)
      // PositiveScore.push(analyticsData[a].AveragePositive)
      // PositiveScore.push(analyticsData[a].TopPositive)

      NegativeScore.push(analyticsData[a].YourNegative)       //Math.abs(analyticsData[a].YourNegative)
      // NegativeScore.push(analyticsData[a].AverageNegative)
      // NegativeScore.push(analyticsData[a].TopNegative)

      categoryName.push(analyticsData[a].Name)

      console.log("yours", TotalScore, PositiveScore, NegativeScore)
    }
    setSeries(
      [
        // {
        //   name: 'Total Score',         //t("you"),
        //   data: TotalScore
        // },
        {
          name: t("attempted"),         //t("you"),
          data: TotalAttemptScore
        },

        {
          name: t("positive"),      //t("avg"),
          data: PositiveScore
        },
        {
          name: t("negative"),      //t("top"),
          data: NegativeScore
        },
      ]
    );
    setOptions(
      {
        chart: {
          id: "basic-bar"
        },
        colors: ['#FC940B', '#009246', '#FB0000'],          //'#F6C100','#ec9706','#34C0D3'
        xaxis: {
          categories: categoryName //['You', 'Average', 'Top']
        },
        yaxis: {
          title: {
            text: analyticsData[0]?.Label == 'SCORE' ? t("score") : analyticsData[0]?.Label
          }
        },
      }
    )
    console.log("TotalScore", TotalScore)

  }, [analyticsData])




  return (
    <div>
      <Chart
        className={styles.tablefont}
        options={options}
        series={series}
        type="bar"
        width="100%"
      />
    </div>
  )
}
