import React from "react";
// material-ui components
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "../CustomButtons/Button";

import { HelpOutline } from "@material-ui/icons";
import CardHeader from "../../Card/CardHeader";
import CardIcon from "../../Card/CardIcon";
import styles from "../../assets/modalStyle";

const useStyles = makeStyles(styles);


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} timeout={1000} />;
});

export default function WarningCustomModal(props) {

  const classes = useStyles();

  return (
    <div>
      <Dialog
        disableEnforceFocus
        open={props.modal}
        transition={Transition}
        onClose={props.setModal}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
        fullWidth={'80%'}
        maxWidth={props.maxWidth}

      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}

        >
          <CardHeader style={{ float: 'left' }}>
            <CardIcon color={"warning"} style={{ color: "white" }}>
              <HelpOutline />
            </CardIcon>
          </CardHeader>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => props.setModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          {props.hideTitle ? (
            <h4 className={classes.modalTitle}>{props.title}</h4>
          ) : null}
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
          style={
            props.padding1
              ? { height: props.height ? props.height : "inherit" }
              : { padding: 1, height: props.height ? props.height : "inherit" }
          }
        >
          {props.content}
        </DialogContent>
        {/* <ModalFooter>
          Balaji
        </ModalFooter> */}
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          {props.showCancel ? (
            <Button
              onClick={() =>
                props.onCancelClick
                  ? props.onCancelClick()
                  : props.setModal(false)
              }
            >
              {props.cancelButtonMessage}
            </Button>
          ) : null}
          {props.showOk ? (
            <Button
              onClick={() =>
                props.onOkClick ? props.onOkClick(props.setModal(false)) : props.setModal(false)
              }
              color="primary"
            >
              {props.okButtonMessage}
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    </div>
  );
}
