import { useTheme } from '@material-ui/core';
import MenuIcon from '@mui/icons-material/Menu';
import { useMediaQuery } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Toolbar from '@mui/material/Toolbar';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';
import { LoginContext } from '../contexts/LoginContext';
import styles from '../styles/LetsStart.module.css';
const drawerWidth = 240;

function LetsStart(props) {

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const theme = useTheme();
  const { t } = useTranslation();
  const { setProviderKey, setProviderName } = useContext(LoginContext);
  const { id } = useParams();
  console.log(id, "idis")
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  // Common Url- uncommit below changes
  // useEffect(() => {
  //   if(id){
  //   const isValid = async () => {
  //     try {

  //       const providerData = await getProviderByKey(id);
  //       console.log("providerkeys",providerData.providerKey)
  //       setProviderKey(providerData.providerKey);
  //       setProviderName(providerData.name);
  //       console.log("names:", providerData.name)
  //       // If the providerData is retrieved, the ID is valid
  //       if(providerData.providerKey != id){

  //        alert("providerkey is not found");
  //       }
  //     } catch (error) {
  //       //  (ID not found ) return false
  //      console.log(error);
  //     }
  //   };

  //   isValid();
  //   }
  // },[]);
  const drawer = (
    <Box onClick={handleDrawerToggle}>
      <List>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'left' }}>
            <NavLink className={styles.Mobilenavlink}
              // Common Url - to={`/signIn/${id}`
              to={`/signIn`}
            >
              {t('@home')}
            </NavLink>
          </ListItemButton>
        </ListItem>
        <Divider />

        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'left' }}>
            <NavLink className={styles.Mobilenavlink}
              // Common Url - to={`/signIn/${id}`
              to={`/signIn`}
            >
              {t('@contactus')}
            </NavLink>
          </ListItemButton>
        </ListItem>
        <Divider />

        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'left' }}>
            <NavLink className={styles.Mobilenavlink}
              // Common Url - to={`/signIn/${id}`
              to={`/signIn`}
            >
              {t('@signIn')}
            </NavLink>
          </ListItemButton>
        </ListItem>

      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar style={{ background: 'white', boxShadow: 'none' }} component="nav">
        <Toolbar style={{ justifyContent: 'end' }}>
          <IconButton
            color="black"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <NavLink className={styles.navlink}
              // Common Url - to={`/signIn/${id}`
              to={`/signIn`}
            >
              {t('@home')}
            </NavLink>
            <NavLink style={{ marginInline: '16px' }} className={styles.navlink}
              // Common Url - to={`/signIn/${id}`
              to={`/signIn`}
            >
              {t('@contactus')}
            </NavLink>
            <NavLink className={styles.navlink}
              // Common Url - to={`/signIn/${id}`
              to={`/signIn`}
            >
              {t('@signIn')}
            </NavLink>
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            fontSize: '16px',

          }}
          PaperProps={{
            sx: {
              backgroundColor: "#5F5C57"
            }
          }}

        >
          {drawer}
        </Drawer>
      </nav>
      {
        isMobile ?
          <img
            src="sidebar-2.jpg"         //src="left5.png"   
            width={'100%'}
            height={'800px'}
          /> :
          <img
            src="splashscreen.png"

            width={'100%'}
            height={'800px'}
          />

      }
    </Box>
  );
}

LetsStart.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   */
  window: PropTypes.func,
};

export default LetsStart;
