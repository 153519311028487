import axios from "axios";
import { configUrl } from "../config";

const getPrivileges = async (userId) => {
  let response = await axios.get(
    `${configUrl.AssessmentServer}/api/User/getPrivileges/${userId}/${true}`
  );
  let data = response.data;

  console.log('privileges123', data)
  return data;
};

export default {
  getPrivileges: getPrivileges,
}