import React from "react";

import { useContext } from "react";
import { TestContext } from "../../contexts/TestContext";
import MultipleChoiceQuestion from "./MultipleChoiceQuestion";
import NumericalTypeQuestion from "./NumericalTypeQuestion";
import SingleChoiceQuestion from "./SingleChoiceQuestion";
import TrueFalseQuestion from "./TrueFalseQuestion";

const Question = ({ key, qNoes, question, updateQuestion, preview, questionObj }) => {
  const { questionIndex, setQuestionIndex } = useContext(TestContext);
  let filteredObj = [];
  questionObj.filter((x, i) => {
    if (x?.Id === question.id) {
      filteredObj.push(x);
    }
  })

  const getQuestionComponent = () => {
    switch (question.type) {
      case "SCQ":
        return (
          <SingleChoiceQuestion
            qNoes={qNoes}
            question={question}
            questionObj={filteredObj}
            contentType={question.contentType}
            updateQuestion={updateQuestion}
            preview={preview}
          />
        );
      case "TF":
        return (
          <TrueFalseQuestion
            qNoes={qNoes}
            question={question}
            questionObj={filteredObj}
            contentType={question.contentType}
            updateQuestion={updateQuestion}
            preview={preview}
          />
        );
      case "MCQ":
        return (
          <MultipleChoiceQuestion
            qNoes={qNoes}
            question={question}
            questionObj={filteredObj}
            contentType={question.contentType}
            updateQuestion={updateQuestion}
            preview={preview}
          />
        );
      case "NUMERICAL":
        return (
          <NumericalTypeQuestion
            qNoes={qNoes}
            question={question}
            questionObj={filteredObj}
            contentType={question.contentType}
            updateQuestion={updateQuestion}
            preview={preview}
          />
        );
      default:
        return null;
    }
  };
  return <>{getQuestionComponent()}</>;
};

export default Question;
