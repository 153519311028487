import axios from "axios";
import { configUrl } from "../config";

const getStudentDetailByEmail = async (email) => {
    console.log(email, 'topicId')
    return axios.get(`${configUrl.AssessmentServer}/api/User/getStudentDetailByEmail/${email}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error?.response?.data, "prevError");
            return {
                messege: error?.response?.data,
                status: error?.response?.status
            }
        });

};
export default {
    getStudentDetailByEmail: getStudentDetailByEmail,
}
