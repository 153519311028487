import axios from "axios";
import CryptoJS from 'crypto-js';
import { configUrl } from "../config";

const key = 'pj23vs7nycq18uew';
let secretKey = CryptoJS.enc.Utf8.parse(key);

const encryptPassword = (password, secretKey) => {

    let encryptedBytes = CryptoJS.AES.encrypt(password, secretKey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    let encryptedString = encryptedBytes.toString();

    console.log(encryptedString, 'encryptedone');
    return encryptedString;
    // return CryptoJS.AES.encrypt(password, secretKey).toString();
}
const decryptPassword = (encryptedPassword, secretKey) => {

    let decryptedBytes = CryptoJS.AES.decrypt(encryptedPassword, secretKey, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    let decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);

    console.log(decryptedText, 'decryptedone');
    return decryptedText;
    // const bytes = CryptoJS.AES.decrypt(encryptedPassword, secretKey);
    // return bytes.toString(CryptoJS.enc.Utf8);
}
const ForgotPassService = async (newPassword, rePassWord, userId) => {
    console.log(newPassword, userId, 'gettingVals')
    let newPass = encryptPassword(newPassword, secretKey)
    let rePass = encryptPassword(rePassWord, secretKey)
    let userPass = decryptPassword(userId, secretKey);
    return axios.post(`${configUrl.AssessmentServer}/api/Counselling/resetPassword?userId=${userPass}`,
        {
            newPassword: newPass,
            confirmPassword: rePass,
        })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error?.response?.data, "prevError");
            return {
                messege: error?.response?.data,
                status: error?.response?.status
            }
        });

};
const EmailForForgotPass = async (forgotEmail) => {
    console.log(forgotEmail, 'gettingVals')
    return axios.post(`${configUrl.AssessmentServer}/api/Counselling/forgotPasswordLearner?emailId=${forgotEmail}`)
        // {
        //     newPassword: newPassword,
        //     confirmPassword: rePassWord,
        // })
        .then((response) => {
            console.log(response, 'respEmail')
            return response;
        })
        .catch((error) => {
            console.log(error?.response?.data, "prevError");
            return {
                messege: error?.response?.data,
                status: error?.response?.status
            }
        });

};
export default {
    ForgotPassService: ForgotPassService,
    EmailForForgotPass: EmailForForgotPass,
}