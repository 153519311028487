import axios from "axios";
import { configUrl } from "../config";

export async function onSiblingInfo(siblingInfo) {
  console.log(siblingInfo, "siblingInfos");
  return axios
    .put(
      `${configUrl.AssessmentServer}/api/Student/updateSiblingDetails`, [siblingInfo]
    )
    .then((respose) => {
      console.log(respose.data.$values);
      return respose.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export async function onParentInfo(parentInfo) {
  console.log(parentInfo, "parentInfos");
  return axios
    .put(
      `${configUrl.AssessmentServer}/api/Student/updateStudentDetails`, parentInfo
    )
    .then((respose) => {
      console.log(respose.data.$values);
      return respose.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export async function getSiblingData(userId) {
  console.log("get sibling data for update", userId);
  return axios
    .get(
      `${configUrl.AssessmentServer}/api/Student/getSiblingDetail/${userId}`
    )
    .then((respose) => {
      console.log(respose.data.$values);
      return respose.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export async function getStudentDetail(studentId) {
  console.log("get sibling data for update", studentId);
  return axios
    .get(
      `${configUrl.AssessmentServer}/api/User/getStudentDetail/${studentId}`
    )
    .then((respose) => {
      console.log(respose, 'response123')
      console.log(respose.data.$values);
      return respose.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
