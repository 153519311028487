import React from 'react';
import { useTranslation } from 'react-i18next';

export default function WarningMessege(props) {
  const { t } = useTranslation();

  return (
    <div>
      <h3 style={{ textAlign: 'center', marginTop: 30, marginBottom: 30 }}>{props.message}</h3>
    </div>
  )
}
