import axios from "axios";
import { useContext } from "react";
import { useQuery } from "react-query";
import { configUrl } from "../config";
import { LoginContext } from "../contexts/LoginContext";


export default function useGetCourseCount() {
  const { UserId } = useContext(LoginContext);
  console.log(UserId, "seriesKeys")
  return useQuery("courses", async () => {
    let response = await axios.get(
      `${configUrl.AssessmentServer}/api/Courses/getCourseStatusCount/${UserId}`
    );
    console.log(response, "responseSeries")
    return response.data;
  },  
  {
    staleTime: 1000 * 60 * 5,
  });
}
