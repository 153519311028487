import axios from "axios";
import { configUrl } from "../config";

// export async function GetProvider() {
//   const { providerId } = useContext(LoginContext);
//   console.log("get data for update", providerId);
//   return axios
//     .get(`${configUrl.AssessmentServer}/api/Provider/${providerId}`)
//     .then((respose) => {
//       console.log(respose.data.name);
//     //   BSS121
//     //   want to check payload and bind provider data
//       return respose.data.name;
//     })
//     .catch((error) => {
//       console.log(error);
//       return error;
//     });
// }

// export default function GetProvider() {
//   const { providerId,setProviderKey, setProviderName } = useContext(LoginContext);
//   console.log(providerId);
//   return useQuery(
//     "assignedCoursesCount",
//     async () => {
//       let response = await axios.get(
//         `${configUrl.AssessmentServer}/api/Provider/${providerId}`
//       );
//       return response.data;
//     },
//     {
//       staleTime: 1000 * 60 * 5,
//     }
//   );
// }

// const getProviderByKey = async (key) => {
//   try {
//     console.log("The key for the url is ======================" + key);
//     let response = await axios.get(
//       `${configUrl.AssessmentServer}/api/Provider/${key}`
//     );

//     console.log(response.data);

//     return response.data;
//   } catch (error) {
//     console.error("error in fetching details.");
//   }
// };

export default async function getProviderByKey(key) {
  try {
    console.log("The key for the url is =" + key);
    let response = await axios.get(
      // `${configUrl.AssessmentServer}/api/Provider/${key}`
      `${configUrl.AssessmentServer}/api/Provider/GetProviderByKey/${key}`
    );
    console.log("myresponse");
    console.log("The key from url : " + key);
    console.log(
      "ProviderId of the corresponding key from url: " +
        response.data.providerId
    );
    // console.log(response.data.providerKey);

    return response.data;
  } catch (error) {
    console.error("error in fetching details.");
  }
}

// export default {
//   GetProvider: GetProvider,
//   getProviderByKey: getProviderByKey,
// };
