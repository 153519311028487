import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LoginContext } from '../contexts/LoginContext';
import styles from '../styles/TestSeriesCard.module.css';


export default function TestSeriesCard({ courseName, objective, image, description, courseId, code, courseTestStatusObj, targetAudience }) {

  const { t } = useTranslation();
  const { UserId, setPageKey, setCourseId, assessment, setAssessmet, testSeriesObj, setTestSeriesObj, setTestSeriesCardStatus, setTopicId } = useContext(LoginContext)

  async function oncall(courseId, testSeriesCardStatus) {
    // const courseTestDetails = await UseGetCourseAssignedTests(UserId ? UserId : null, testSeriesCardStatus, courseId ?? null)
    // console.log(courseTestDetails, "courseTestDetails")
    // setTestSeriesObj(courseTestDetails);
    setPageKey(5)
    console.log("testseriescarsstatus", testSeriesCardStatus);
    setTestSeriesCardStatus(testSeriesCardStatus)
    setTopicId(courseId);
    setAssessmet(false)
  }

  return (
    <Card style={{ marginBottom: '40px' }} sx={{ maxWidth: 345 }}>
      <CardMedia
        component="img"
        alt="series Image"
        height="140"
        image={image ?? 'carddefaultimg.png'}
      />
      <CardContent>
        <Typography className={styles.cardHeader} gutterBottom variant="h5" component="div">
          {courseName.length > 38 ? courseName.substr(0, 38) + '...' : courseName}
        </Typography>
        <Typography className={styles.cardDescription} variant="body2" color="text.secondary">
          {objective ? objective.substr(0, 135) : <i>none</i>} {targetAudience ? `(${targetAudience.substr(0, 135)})` : ''}
        </Typography>
      </CardContent>
      <div className={styles.actionContainer} xs={12} sm={6} md={6} lg={3}>
        <Button color='warning' disabled={!courseTestStatusObj} className={styles.cardActionsButtons} onClick={() => oncall(courseId, "ALL")} size="small">{t('assignedTests')} ({`${courseTestStatusObj ? courseTestStatusObj.total : 0}`})</Button>
        <Button style={{ color: courseTestStatusObj ? '#E8403C' : '' }} disabled={!courseTestStatusObj} className={styles.cardActionsButtons} onClick={() => oncall(courseId, "INPROGRESS")} size="small">{t('inprogress')} ({`${courseTestStatusObj ? courseTestStatusObj.inProgress : 0}`})</Button>
        <Button style={{ color: courseTestStatusObj ? '#0FB6CB' : '' }} disabled={!courseTestStatusObj} className={styles.cardActionsButtons} onClick={() => oncall(courseId, "PENDING")} size="small">{t('pending')} ({`${courseTestStatusObj ? courseTestStatusObj.pending : 0}`})</Button>
        <Button color='success' disabled={!courseTestStatusObj} className={styles.cardActionsButtons} onClick={() => oncall(courseId, "COMPLETED")} size="small">{t('completed')} ({`${courseTestStatusObj ? courseTestStatusObj.completed : 0}`}) </Button>
      </div>
    </Card>
  );
}