import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { LoginContext } from '../contexts/LoginContext';
import styles from '../styles/DetailingTable.module.css';

function createData(topicName, yourScore, avgScore, topScore,) {
  return {
    topicName,
    yourScore,
    avgScore,
    topScore,
  };
}

function Row(props) {

  const { analyticsData, packageId } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow className={styles.tableRow}>
        {/*
        <TableCell className={styles.tablefont}>
           <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ?<IndeterminateCheckBox/>:<AddBoxOutlined />}
          </IconButton> 
        </TableCell>
          */}
        <TableCell className={styles.tablefont} component="th" scope="row">{analyticsData.Name}</TableCell>
        {
          packageId == 1 || packageId == 2 || packageId == 3 ?
            <TableCell className={styles.tablefont} align="center"><button className={styles.yourScore} disabled>{analyticsData.Your}</button> </TableCell> : null
        }
        {
          packageId == 3 ?
            <TableCell className={styles.tablefont} align="center"><button className={styles.avgScore} disabled>{analyticsData.Average}</button></TableCell> : null
        }
        {
          packageId == 3 ?
            <TableCell className={styles.tablefont} align="center"><button className={styles.topScore} disabled>{analyticsData.Top}</button></TableCell> : null
        }
      </TableRow>
      <TableRow>
        {/* Inner Table collapsable table */}
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table aria-label="purchases">
                <TableBody>
                  {Object.keys(analyticsData.SubObjectList).length > 0 ?
                    analyticsData.SubObjectList.map((row, j) => (
                      <TableRow key={analyticsData.SubObjectList[j].Id}>
                        <TableCell className={styles.tablefont} component="th" scope="row">{analyticsData.SubObjectList[j].Name}</TableCell>
                        <TableCell className={styles.tablefont}>{analyticsData.SubObjectList[j].Your}</TableCell>
                        <TableCell className={styles.tablefont}>{analyticsData.SubObjectList[j].Average}</TableCell>
                        <TableCell className={styles.tablefont}>{analyticsData.SubObjectList[j].Top}</TableCell>
                      </TableRow>
                    ))
                    : ''
                  }
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function ChartTable({ analyticsData }) {

  console.log(analyticsData, "graphTable")

  const { t } = useTranslation();
  const { packageId } = React.useContext(LoginContext);
  const [open, setOpen] = React.useState(false);

  // const{analyticsData} = props;
  if (analyticsData) {
    var length = Object.keys(analyticsData).length;
    for (let a = 0; a < length; a++) {

    }
    console.log("analyticData", length)
  }


  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead className={styles.tableHead}>
          <TableRow>
            {/* <TableCell /> */}
            <TableCell className={styles.tablefont}>{t("rowlabel")}</TableCell>
            {
              packageId == 1 || packageId == 2 || packageId == 3 ?
                <TableCell className={styles.tablefont} align="center">{t("you")}</TableCell> : null
            }
            {
              packageId == 3 ?
                <TableCell className={styles.tablefont} align="center">{t("avg")}</TableCell> : null
            }
            {
              packageId == 3 ?
                <TableCell className={styles.tablefont} align="center">{t("top")}</TableCell> : null
            }
          </TableRow>
        </TableHead>
        <TableBody>

          {
            analyticsData ?
              analyticsData.map((row, i) => (
                <>
                  <Row key={analyticsData[i].Id} analyticsData={analyticsData[i]} packageId={packageId} />
                </>

              ))
              : ''
          }

        </TableBody>
      </Table>
    </TableContainer>
  );
}