import axios from "axios";
import { configUrl } from "../../config";

export default async function UseGetGraph(studentId, lOid) {

  return axios.get(`${configUrl.AssessmentServer}/getAnalytics/${1}/${1}/${studentId}/${lOid}`)
    .then((response) => {

      return response.data;

    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
