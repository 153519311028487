import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../styles/Confirmation.module.css';

export default function ConfirmationDialog({ unsolvedQuestions }) {
  const { t } = useTranslation();

  return (
    <div className={styles.AlertmessegeText}>
      {
        unsolvedQuestions === 0 ?
          t('alertMsg2') :
          `${t('alertMsg0')} ${unsolvedQuestions} ${t('alertMsg1')}`
      }
    </div>
  )
}
