import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ImageIcon from "@material-ui/icons/Image";
import 'katex/dist/katex.min.css';
import React from "react";

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: "none",
    },
    paper: {
        padding: theme.spacing(1),
    },
}));

export default function Paragraph({ question, contentType, questionId, messageText, messageImage }) {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(anchorEl == null ? event.currentTarget : null);
        question.hintCount = question.hintCount + 1;
        console.log(question.hintCount, "hintCount");
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <ImageIcon
                style={{ color: "orange", fontSize: 30 }}
                onClick={handlePopoverOpen}
            //onMouseLeave={handlePopoverClose}
            />
            <Popover
                id={`mouse-over-popover-${questionId}`}
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >  {console.log("datsforimages", messageImage, messageText)}
                {
                    (messageImage == null && messageText == null) ?
                        "Content not available" :
                        <>
                            {(messageText != null && messageText !== "") &&
                                <Typography style={{ fontSize: '1.25rem' }}>{messageText}</Typography>
                            }
                            {(messageImage != null && messageImage !== "") &&
                                <img src={messageImage} alt="content" style={{ maxWidth: '100%' }} />
                            }
                        </>
                }
            </Popover>
        </>
    );
}
