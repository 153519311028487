/* eslint-disable import/first */
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import EmojiObjectsTwoToneIcon from "@material-ui/icons/EmojiObjectsTwoTone";
import 'katex/dist/katex.min.css';
import React from "react";
import ReactHtmlParser from "react-html-parser";
import Latex from 'react-latex-next';
{/* latex CSS */ }

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

export default function Hint({ question, contentType, questionId, message }) {

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {

    setAnchorEl(event.currentTarget);
    question.hintCount = question.hintCount + 1;
    console.log(question.hintCount, "hintCount")

  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <EmojiObjectsTwoToneIcon
        style={{ color: "orange", fontSize: 30 }}
        onClick={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        id={`mouse-over-popover-${questionId}`}
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {/* <Typography>{message == null ?'Hint not available' : message}</Typography> */}
        {
          (message == null || message == "") ?
            "Hint not available" :
            contentType == 1 ?
              <Typography>{message}</Typography>
              :
              contentType == 3 ?
                <Latex style={{ color: 'black' }}>{message}</Latex>
                :
                contentType == 4 ?
                  <div style={{ color: 'black' }}>{ReactHtmlParser(message)}</div>
                  :
                  <Typography>{message}</Typography>
        }

      </Popover>
    </>
  );
}
