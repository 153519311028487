import { t } from 'i18next';
import React from 'react';

const DataTable = ({ data }) => {
    console.log(data, 'data1235')
    return (
        <table style={{ border: 'solid 1px #FC940C' }}>
            <thead style={{ background: '#f5910dd1', color: 'white' }}>
                <tr>
                    {/* <th>ID</th> */}
                    <th style={{ padding: '0px 0px 0px 12px' }}>{t("Section")}</th>
                    <th style={{ padding: '0px 0px 0px 12px', textAlign: 'center' }}>{t("question")}</th>
                    <th style={{ padding: '0px 0px 0px 12px', textAlign: 'center' }}>{t("attempted")}</th>
                    <th style={{ padding: '0px 0px 0px 12px', textAlign: 'center' }}>{t("correct")}</th>
                    <th style={{ padding: '0px 0px 0px 12px', textAlign: 'center' }}>{t("incorrect")}</th>
                </tr>
            </thead>
            <tbody>
                {console.log("dataaka", data)}
                {data && data?.length > 0 ?
                    data.map((section, index) => (
                        <tr key={section.id} >
                            <td style={{ padding: '0px 0px 0px 12px' }}>{section?.poolName}</td>
                            <td style={{ padding: '0px 0px 0px 12px', textAlign: 'center' }}>{section?.totalCount}</td>
                            <td style={{ padding: '0px 0px 0px 12px', textAlign: 'center' }}>{section?.attemptedCount}</td>
                            <td style={{ padding: '0px 0px 0px 12px', textAlign: 'center' }}>{section?.correctCount}</td>
                            <td style={{ padding: '0px 0px 0px 12px', textAlign: 'center' }}>{section?.incorrectCount}</td>
                        </tr>
                    ))
                    : <tr>
                        <td colspan="5" style={{ textalign: 'center' }}>
                            <h6 >No Record Found</h6>
                        </td>
                    </tr>

                }
            </tbody>
        </table>
    );
};

export default DataTable;