//import QuestionComponent from "./QuestionComponent";

import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";


import Button from "../CustomButtons/Button";


import { Tooltip, makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { useContext } from "react";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { TestContext } from "../../contexts/TestContext";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    fontSize: "1em",
  },
}));
const WelcomeScreen = ({ message, handleConfirm, startButtonTime, testStarted }) => {
  const { testTFlag, setTestTFlag, testAttemptDetails, } = useContext(TestContext);
  const { t } = useTranslation();
  const classes = useStyles();
  function getDiff() {
    const currentDate = new Date(); // Current date in local timezone
    const currentUtcDate = new Date(currentDate.toUTCString());

    const timezoneOffsetLocal = currentDate.getTimezoneOffset(); // Offset in minutes
    const timezoneOffsetUTC = currentUtcDate.getTimezoneOffset(); // Offset in minutes

    const offsetHoursLocal = Math.abs(timezoneOffsetLocal / 60);
    const offsetMinutesLocal = Math.abs(timezoneOffsetLocal % 60);

    const offsetHoursUTC = Math.abs(timezoneOffsetUTC / 60);
    const offsetMinutesUTC = Math.abs(timezoneOffsetUTC % 60);

    console.log(`UTC offset: ${offsetHoursUTC} hours ${offsetMinutesUTC} minutes`);
    console.log(`Local timezone offset: ${offsetHoursLocal} hours ${offsetMinutesLocal} minutes`);
    return offsetHoursLocal;
  }
  const toLocal = (UTCTime) => {
    // converting into local time start
    const utcDateTime = new Date(UTCTime);
    let diff = getDiff();
    console.log(diff, 'inDiff')
    const istOffset = diff * 60 * 60 * 1000; // IST is UTC+5.5 hours
    const istDateTime = new Date(utcDateTime.getTime() + istOffset);
    return istDateTime
  }
  const onStartClick = () => {
    testAttemptDetails.currentPageIndex = 1;
    console.log("redirected to questionwrapper screen - ", testAttemptDetails.currentPageIndex)
    setTestTFlag(true);
    console.log("start button Clicked", new Date())
    let time = new Date();
    let flag = true;
    startButtonTime(time, flag);

  }
  console.log(testAttemptDetails, 'testAttemptDetails123')

  const targetStartDateTime = testAttemptDetails?.testStartDate ? toLocal(testAttemptDetails.testStartDate) : null;
  const targetEndDateTime = testAttemptDetails?.testEndDate ? toLocal(testAttemptDetails.testEndDate) : null;
  const currentDateTime = new Date();

  console.log(targetStartDateTime, " AAA ", currentDateTime, 'compareStartDate');
  console.log(targetStartDateTime <= currentDateTime, 'compareStartDate1');

  return (
    <div>
      <Card >
        <CardBody style={{ textAlign: 'center', paddingTop: '11%' }}>
          {
            (targetStartDateTime <= currentDateTime && targetEndDateTime >= currentDateTime) || (testAttemptDetails?.testStartDate == null && testAttemptDetails?.testEndDate == null) ?
              (<h4 style={{ textAlign: 'center' }}>{ReactHtmlParser(message)}</h4>) : null
          }
          <Box p={1} justifyContent="center" alignContent="center">
            {
              (targetStartDateTime <= currentDateTime && targetEndDateTime >= currentDateTime) || (testAttemptDetails?.testStartDate == null && testAttemptDetails?.testEndDate == null) ?
                <Tooltip classes={{ tooltip: classes.tooltip }} title="Press start to begin the assessment">
                  <Button round color={"primary"} onClick={onStartClick}>
                    {t("start")}
                  </Button>
                </Tooltip>
                :
                targetEndDateTime <= currentDateTime ?
                  <h5 style={{ color: '#FB0000' }}>Sorry, you cannot attempt this test now because the test has expired.!</h5>
                  :
                  <>
                    <h5 >Your test is scheduled at {"  "}
                      <Moment style={{ color: '#fd9912' }} format="DD-MMM-YYYY hh:mm:ss a">{toLocal(testAttemptDetails?.testStartDate)}</Moment>
                    </h5>
                  </>

            }
          </Box>
        </CardBody>
      </Card>
    </div>

  );
};

export default WelcomeScreen;
