import axios from "axios";
import { configUrl } from "../config";

export default async function useGetAttemptDetails(userId, testId, attemptNumber) {

  return axios.get(`${configUrl.AssessmentServer}/api/Tests/getAttemptDetails/${userId}/${testId}/${attemptNumber}`)
    .then((response) => {

      return response.data;

    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
