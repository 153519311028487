import { CircularProgress } from '@material-ui/core';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import CustomModal from '../Components/CustomModal/CustomModal';
import { UseGetPieChartHeirarchy } from '../Components/analyticsHooks/UseGetPieChartData';
import TreeHierarchy from '../Tables/TreeHierarchy';
import { LoginContext } from '../contexts/LoginContext';

const PieChartComponent = ({ pieChartAnalyticsData }) => {
    console.log("Received data:", pieChartAnalyticsData);
    const { UserId } = useContext(LoginContext);
    const [evaluationModel, setEvaluationModel] = React.useState(null);
    const [pieChartHeirarchyData, setPieChartHeirarchyData] = React.useState();

    useEffect(() => {
        const getHeirarchyData = async () => {
            try {
                const heirarchydata = await UseGetPieChartHeirarchy(UserId);
                console.log("sampledata", heirarchydata);
                if (heirarchydata != null) {
                    setPieChartHeirarchyData(heirarchydata);
                }
            } catch (e) {
                console.log("errrr getting heirarchy data", e);
            }
        }
        getHeirarchyData();
    }, [UserId])
    const [data, setData] = useState({
        totalQuestions: 0,
        unattempted: 0,
        correct: 0,
        incorrect: 0,
    });

    const showAnalytics = async (lOid, testName) => {
        console.log('Click3')
        setEvaluationModel(
            <CustomModal
                modal={true}
                setModal={hideEvaluationModel}
                content={
                    <TreeHierarchy analyticsData={pieChartHeirarchyData} pieChartAnalyticsData={pieChartAnalyticsData} />
                }
                showOk={false}
                showCancel={false}
                onCancelClick={hideEvaluationModel}
                title={t("whereareYou")}
                maxWidth="lg"
                hideTitle={true}
                fullScreen={true}
                fullWidth={false}
                padding={-1}
            />
        )
    }
    const hideEvaluationModel = (event, reason) => {
        if (reason) console.log("reason", reason);
        setEvaluationModel(null);
    };


    useEffect(() => {
        if (pieChartAnalyticsData) {
            setData({
                totalQuestions: pieChartAnalyticsData.totalQuestions ?? 0,
                unattempted: pieChartAnalyticsData.unattempted ?? 0,
                correct: pieChartAnalyticsData.correct ?? 0,
                incorrect: pieChartAnalyticsData.incorrect ?? 0,
            });
        }
    }, [pieChartAnalyticsData]);

    const pieData = {
        series: [data.unattempted, data.correct, data.incorrect],
        options: {
            chart: {
                type: 'pie',
            },
            // #1D63E7(blue)#A9A9A9(dark grey) #D3D3D3(light grey)
            // '#9da39d' (Try grey learnerinterface)
            labels: [t("unattempted"), t("correct"), t("incorrect")],
            colors: ['#A9A9A9', '#4CAF50', '#EC192F'], // colors for unattempted, correct, incorrect
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val + t("questions");
                    }
                }
            },
            responsive: [{
                breakpoint: 450,
                options: {
                    chart: {
                        // width: 500
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
            legend: {
                position: 'bottom',
                labels: {
                    colors: ['#FFCE56', '#4CAF50', '#FF6384'],
                    useSeriesColors: false
                }
            }
        }
    };

    return (
        <div style={{ width: '100%', height: '100%', minHeight: '375px', margin: '0 auto', height: "280px" }}>
            {/* <div> */}
            {pieChartAnalyticsData && pieChartAnalyticsData != null && pieChartAnalyticsData?.totalQuestions == 0 ? <h5 style={{ color: 'GrayText', paddingTop: '30px' }}>Analytics not found</h5> : null}
            {pieChartAnalyticsData ?
                <ReactApexChart options={pieData.options} series={pieData.series} type="pie" height={350} />
                : <CircularProgress
                    style={{ size: "1rem", color: "#FF802B", marginLeft: '40px', marginTop: '120px' }}
                />}
            {
                pieChartAnalyticsData && pieChartHeirarchyData ?
                    <button
                        style={{
                            position: 'absolute',
                            bottom: '20px',
                            right: '15px',
                            padding: '5px 10px',
                            backgroundColor: 'transparent',
                            color: '#0000FF',
                            border: '2px ',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            fontSize: '13px',
                            transition: 'background-color 0.3s, color 0.3s, border-color 0.3s'
                        }}
                        onMouseEnter={(e) => {
                            e.target.style.backgroundColor = '#E0F7FA'; // Blue background on hover
                            e.target.style.color = '#0000FF'; // White text color on hover
                            e.target.style.borderColor = '#0000FF'; // Blue border on hover
                        }}
                        onMouseLeave={(e) => {
                            e.target.style.backgroundColor = 'transparent'; // Transparent background
                            e.target.style.color = '#0000FF'; // Blue text color
                            e.target.style.borderColor = '#0000FF'; // Blue border
                        }}
                        onClick={showAnalytics}
                    >
                        {t("seeDetails")}
                    </button>
                    : null
            }
            {evaluationModel}
        </div>
    );
};

export default PieChartComponent;
