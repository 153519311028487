import AttachmentIcon from '@mui/icons-material/Attachment';
import React, { useState } from 'react';
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import styles from '../../styles/NotificationDetails.module.css';

export default function NotificationDetails({ notificationDetails }) {
  const { t } = useTranslation();
  const [notification, setNotification] = React.useState(notificationDetails ? notificationDetails?.notification : [])
  const [isOpen, setIsopen] = useState(false);
  console.log(notificationDetails?.fileNames, 'notificationDetails123')

  let fileNames = notificationDetails?.fileNames && notificationDetails?.fileNames;

  function getDiff() {
    const currentDate = new Date(); // Current date in local timezone
    const currentUtcDate = new Date(currentDate.toUTCString());

    const timezoneOffsetLocal = currentDate.getTimezoneOffset(); // Offset in minutes
    const timezoneOffsetUTC = currentUtcDate.getTimezoneOffset(); // Offset in minutes

    const offsetHoursLocal = Math.abs(timezoneOffsetLocal / 60);
    const offsetMinutesLocal = Math.abs(timezoneOffsetLocal % 60);

    const offsetHoursUTC = Math.abs(timezoneOffsetUTC / 60);
    const offsetMinutesUTC = Math.abs(timezoneOffsetUTC % 60);

    console.log(`UTC offset: ${offsetHoursUTC} hours ${offsetMinutesUTC} minutes`);
    console.log(`Local timezone offset: ${offsetHoursLocal} hours ${offsetMinutesLocal} minutes`);
    return offsetHoursLocal;
  }
  const toLocal = (UTCTime) => {
    // converting into local time start
    const utcDateTime = new Date(UTCTime);
    let diff = getDiff();
    console.log(diff, 'inDiff')
    const istOffset = diff * 60 * 60 * 1000; // IST is UTC+5.5 hours
    const istDateTime = new Date(utcDateTime.getTime() + istOffset);
    return istDateTime
  }
  return (
    <div className={styles.notificationcontainer}>
      <div className={styles.messegeDesc}>
        <h5 style={{ textAlign: 'end' }}> <Moment format="DD-MMM-YYYY hh:mm:ss a">{notification ? toLocal(notification.sendDateTime) : ''}</Moment> {fileNames?.image || fileNames.pdf ? <button style={{ backgroundColor: 'transparent', border: 'none' }} onClick={() => setIsopen((prev) => !prev)}><AttachmentIcon fontSize='large' /></button> : null}</h5>
        {isOpen ?
          fileNames?.image ?
            <div id="carouselExample" class="carousel slide">
              <div class="carousel-inner">
                <div class="carousel-item active">
                  {/* will map images for multiple attachments */}
                  <img src={fileNames?.image[0]} alt="openedModal here" style={{ maxWidth: '100%', height: 'auto' }} />
                </div>
              </div>
              <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
            : fileNames.pdf ?
              <iframe src={fileNames?.pdf[0]} name="iframe_a" height="300px" width="100%" title="Iframe Example"></iframe> : null
          :
          <div className={styles.messege}>
            <h4 className={styles.body} >{ReactHtmlParser(notification ? notification.body : '')}</h4>
          </div>
        }
      </div>
      {/* 
              <h6 >{notification?notification.createdBy:''}</h6> */}

    </div>
  )
}
