import axios from "axios";
import { configUrl } from "../../config";

export async function UseGetTopFiveAnalytics(UserId) {
  console.log("get data for update", UserId);
  return axios
    .get(
      `${configUrl.AssessmentServer}/getAnalytics/${UserId}`
    )
    .then((respose) => {
      console.log("topfive", respose.data.$values);
      return respose.data.$values;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}