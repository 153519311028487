import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { configUrl } from '../config';

export default function useCreateTestResult() {
  const queryClient = useQueryClient();

  return useMutation(async (result) => {
    console.log("post result", result)
    await axios.post(`${configUrl.AssessmentServer}/api/TestResults`, result);
  },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("assignedTests");
      },
    });
}


