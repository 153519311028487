import axios from "axios";
import { configUrl } from "../config";

const attemptwiseService = async (id, testId, attemptId) => {
    let response = await axios.get(
        `${configUrl.AssessmentServer}/api/Tests/getAttemptDetails/${id}/${testId}/${attemptId}`
    );
    console.log(response.data, 'responseData')
    return response.data;
};
export default {
    attemptwiseService: attemptwiseService,
}