import axios from "axios";
import { useQuery } from "react-query";
import { configUrl } from "../../config";


export default function UseGetAnalytics2D(typeId, subTypeId, studentId, lOid) {
  return useQuery([`testsByUser${lOid}`, typeId, subTypeId, studentId, lOid], async () => {
    console.log('in here', typeId, subTypeId, studentId, lOid)
    if (!typeId || !subTypeId || !studentId || !lOid) {
      return;
    }   //loid means Test Id
    let result = await axios.get(
      `${configUrl.AssessmentServer}/getAnalytics/${typeId}/${subTypeId}/${studentId}/${lOid}`
    );
    console.log("2Danay", result.data)

    return result.data;
  },
    {
      staleTime: 1000 * 60 * 5,
    });
}
