import axios from "axios";
import { configUrl } from "../config";

export async function onEducationInfo(educationInfo) {
    console.log(educationInfo,"parentInfos");
    return axios
      .put(
        `${configUrl.AssessmentServer}/api/Student/updateEducationInformation`,educationInfo
      )
      .then((respose) => {
        console.log(respose.data.$values);
        return respose.data;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  }
